import * as React from 'react';

import {
  Button,
  PRIORITY as ButtonPRIORITY,
  SIZE as ButtonSIZE,
} from 'wix-ui-tpa/Button';

import styles from './LeaveTheChallenge.st.css';

export interface ILeaveTheChallengeProps {
  t(key: string): string;
  onLeave(): void;
  onCancel(): void;
  isMobile: boolean;
}

export interface ILeaveTheChallengeState {}

export class LeaveTheChallenge extends React.Component<
  ILeaveTheChallengeProps,
  ILeaveTheChallengeState
> {
  static displayName = 'LeaveTheChallenge';

  constructor(props: ILeaveTheChallengeProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, isMobile, ...rest } = this.props;

    return (
      <div
        {...styles(
          'root',
          {
            mobile: isMobile,
          },
          rest,
        )}
      >
        <h1 className={styles.title}>{t('leave-the-challenge.title')}</h1>
        <p className={styles.description}>
          {t('leave-the-challenge.description')}
        </p>

        <div className={styles.controls}>
          <Button
            disabled={false}
            className={styles.cancel}
            priority={ButtonPRIORITY.basicSecondary}
            size={ButtonSIZE.medium}
            onClick={this.props.onCancel}
          >
            {t('leave-the-challenge.cancel')}
          </Button>
          <Button
            disabled={false}
            className={styles.cta}
            priority={ButtonPRIORITY.basic}
            size={ButtonSIZE.medium}
            onClick={this.props.onLeave}
          >
            {t('leave-the-challenge.cta')}
          </Button>
        </div>
      </div>
    );
  }
}
