import { V1Challenge } from '@wix/ambassador-challenge-service-web/types';
import {
  getChallengePaidPlans,
  isChallengeFree,
} from '@wix/challenges-web-library/dist/src';

export function getPricingViewOptions(
  challenge: V1Challenge,
  userPaidPlans: any[],
) {
  const challengePricing = challenge.settings.pricing;
  const challengePaidPlans =
    getChallengePaidPlans(challenge.id, userPaidPlans) || [];
  const isFree = isChallengeFree(challengePricing, challengePaidPlans.length);
  const isHasSinglePayment = !!challengePricing.singlePayment;
  const isHasPaidPlans = challengePaidPlans.length;

  return {
    challengePricing,
    challengePaidPlans,
    isFree,
    isHasSinglePayment,
    isHasPaidPlans,
  };
}
