import {
  IUserType,
  UserState,
} from '../../../../../../contexts/User/UserContext';
import { ParticipantState } from '@wix/ambassador-challenge-service-web/types';

interface IGetClickHandlerArgs {
  isMemberFlowExperimentEnabled: boolean;
  joinHandler: Function;
  cancelHandler: Function;
  userType: IUserType;
}

export function getJoinClickHandler({
  isMemberFlowExperimentEnabled,
  joinHandler,
  userType,
  cancelHandler,
}: IGetClickHandlerArgs): Function {
  if (!isMemberFlowExperimentEnabled) {
    return () => {};
  }

  switch (userType) {
    case UserState.VISITOR:
    case UserState.MEMBER:
    case ParticipantState.INVITED:
    case ParticipantState.PAYMENT_REQUESTED:
    case ParticipantState.PAYMENT_STARTED:
    case ParticipantState.REMOVED:
    case ParticipantState.LEFT:
    case ParticipantState.COMPLETED:
    case ParticipantState.JOIN_REJECTED:
    case ParticipantState.FAILED:
      return joinHandler;
    case ParticipantState.JOIN_REQUESTED:
      return cancelHandler;
    case ParticipantState.JOINED:
    case ParticipantState.RUNNING:
    default:
      return () => {};
  }
}
