import React from 'react';
import styles from './UserBox.st.css';
import { withGeneralData } from '../../contexts/GeneralDataProvider/withGeneralData';
import { IGeneralDataContext } from '../../contexts/GeneralDataProvider/GeneralDataContext';
import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';

export interface IUserBoxProps {
  imageUrl: string;
  fullName: string;
  size?: AvatarSize;
  alignment?: any;
  dataHook?: string;
  className?: string;
}

export type IInnerUserBox = IUserBoxProps & IGeneralDataContext;

export const UserBoxComponent: React.FC<IInnerUserBox> = props => {
  const {
    imageUrl,
    fullName,
    formFactor,
    size = AvatarSize.medium,
    alignment,
    dataHook,
    className,
  } = props;
  const mobile = formFactor === 'Mobile';

  return (
    <div
      {...styles(
        'root',
        { mobile, alignment },
        {
          className,
        },
      )}
      data-hook={dataHook}
    >
      <Avatar
        src={imageUrl}
        size={size}
        name={fullName}
        className={styles.pic}
      />
      <div className={styles.info}>
        <span className={styles.name}>{fullName}</span>
      </div>
    </div>
  );
};

export default withGeneralData<IUserBoxProps>(UserBoxComponent);
