import * as React from 'react';
import styles from './radioGroup.st.css';
import classNames from 'classnames';
import { RadioButton } from '../../../../../components-shared/RadioButton';
import { applyProviders } from '../../../../../services/applyProviders';
import { withGeneralData } from '../../../../../contexts/GeneralDataProvider/withGeneralData';
import { IGeneralDataContext } from '../../../../../contexts/GeneralDataProvider/GeneralDataContext';

export interface RadioOption {
  dataHook: string;
  title: string;
  valueTitle: string;
  value?: any;
}

export interface RadioGroupProps {
  options: RadioOption[];
  selectedValue: any;
  isDisabled?: boolean;
  isMobile?: boolean;
  onSelect(selected: RadioOption): void;
}

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const { isDisabled = false, isMobile = false } = props;

  return (
    <div {...styles('root', { mobile: isMobile, disabled: isDisabled })}>
      {props.options.map((option, index) => {
        const { dataHook, title, valueTitle } = option;
        const checked = option.value === props.selectedValue;

        return (
          <label
            key={index}
            className={classNames(styles.radioButtonGroupItem, {
              [styles.radioButtonGroupItemChecked]: checked,
            })}
            data-hook={dataHook}
          >
            {!isDisabled && (
              <RadioButton
                onChange={() => {
                  props.onSelect(option);
                }}
                checked={checked && !isDisabled}
              />
            )}
            <div className={styles.radioButtonGroupInfoContainer}>
              <span className={styles.radioButtonGroupItemTitle}>{title}</span>
              <span className={styles.radioButtonGroupItemValue}>
                {valueTitle}
              </span>
            </div>
          </label>
        );
      })}
    </div>
  );
};

export const RadioGroupContainer: React.FC<RadioGroupProps> = applyProviders(
  (props: RadioGroupProps & IGeneralDataContext) => {
    return <RadioGroup {...props} isMobile={props.formFactor === 'Mobile'} />;
  },
  [withGeneralData],
);
