import {
  IUserPaidPlan,
  serverPricingToClientPriceAndCurrencyString,
  TranslateFunction,
} from '@wix/challenges-web-library/dist/src';

function getPaidPlanWithLowestPrice(paidPlans: IUserPaidPlan[]) {
  if (!paidPlans) {
    return null;
  }
  return paidPlans.reduce((paidPlanWithLowerPrice, paidPlan) => {
    if (
      !paidPlanWithLowerPrice ||
      parseFloat(paidPlanWithLowerPrice.paymentOptions.price.amount) >
        parseFloat(paidPlan.paymentOptions.price.amount)
    ) {
      return paidPlan;
    }
    return paidPlanWithLowerPrice;
  }, null);
}

export function getPaidPlanPrice(
  paidPlans: IUserPaidPlan[],
  t: TranslateFunction,
) {
  const paidPlanWithLowestPrice = getPaidPlanWithLowestPrice(paidPlans);

  if (!paidPlanWithLowestPrice) {
    return null;
  }

  const price = serverPricingToClientPriceAndCurrencyString({
    singlePayment: {
      price: paidPlanWithLowestPrice.paymentOptions.price,
    },
  });

  if (paidPlanWithLowestPrice.paymentOptions.price.amount === '0') {
    return t('pricing.payment-option.subscription.free');
  }

  if (!paidPlanWithLowestPrice.paymentOptions.recurring) {
    return t('pricing.payment-option.subscription.one-time-payment', {
      price,
    });
  }

  return t(
    `pricing.payment-option.subscription.recurring.${paidPlanWithLowestPrice.paymentOptions.validFor.period.unit.toLowerCase()}`,
    {
      price,
    },
  );
}

export function getPaidPlanStartPrice(
  paidPlans: IUserPaidPlan[],
  t: TranslateFunction,
) {
  if (
    paidPlans.length === 1 &&
    getPaidPlanWithLowestPrice(paidPlans).paymentOptions.price.amount === '0'
  ) {
    return t('challenge-card.pricing.free');
  }

  if (
    paidPlans.length === 1 ||
    getPaidPlanWithLowestPrice(paidPlans).paymentOptions.price.amount === '0'
  ) {
    return getPaidPlanPrice(paidPlans, t);
  }

  return t('pricing.payment-option.starts-from', {
    price: getPaidPlanPrice(paidPlans, t),
    interpolation: {
      escapeValue: false,
    },
  });
}
