import { IWithTranslationProps } from '../../../contexts/main/intefaces';
import { IResolveStepContext } from '../../../contexts/ResolveStep/ResolveStepContext';
import { IParticipantStepsContext } from '../../../contexts/ParticipantStepsDataProvider/ParticipantStepsContext';
import { IChallengeDataProps } from '../../../contexts/ChallengeDataProvider/challengeDataProviderPropsMap';
import { IGeneralDataProviderProps } from '../../../contexts/GeneralDataProvider/generalDataProviderPropsMap';
import { IUserContext } from '../../../contexts/User/UserContext';
import { ILocationProps } from '../../../contexts/Location/locationProviderPropsMap';
import { IExperimentsProviderProps } from '../../../contexts/Experiments/interfaces';
import { BIProviderPropsMap } from '../../../contexts/BI/interfaces';

import { IChallengeSettings } from '../Settings/challengeSettings/challengeSettings.types';
import { UnpackSettingsValues } from '../../../types/helpers';
import { IInviteLinkProviderProps } from '../../../contexts/InviteLink/interfaces';
import { SelectedPaymentOption } from './components/Pricing/interfaces';
import { IToastProviderProps } from '../../../contexts/ToastContext/ToastProvider';
import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';

export type IChallengePageSettingsValues = UnpackSettingsValues<
  IChallengeSettings
> & {
  buttonState: 'default' | 'hover';
};

export interface IChallengePageProps
  extends BIProviderPropsMap,
    IExperimentsProviderProps,
    ILocationProps,
    IUserContext,
    IGeneralDataProviderProps,
    IChallengeDataProps,
    IParticipantStepsContext,
    IResolveStepContext,
    IInviteLinkProviderProps,
    IToastProviderProps,
    IWithTranslationProps {
  inviteLink: string;
  settings: IChallengePageSettingsValues;
  userPaidPlans: any;
  isLoading: boolean;
  isMemberFlowEnabled: boolean;
  host?: any;
}

export const enum IChallengeJoinRestriction {
  MAX_PARTICIPANTS = 'MAX_PARTICIPANTS',
  SPECIFIC_STARTED = 'SPECIFIC_STARTED',
  FINISHED = 'FINISHED',
}

export interface IChallengePageState {
  isError: boolean;
  startDate: string;
  isStartDateFirstTouch?: boolean;
  selectedTabIndex?: number;
  currentStep?: V1ParticipantStep;
  isFeedbackModalOpened?: boolean;
  isLeaveModalOpened?: boolean;
  selectedPaymentOption: SelectedPaymentOption | null;
  isChallengeDisabledForJoin?: boolean;
  challengeJoinRestrictions?: IChallengeJoinRestriction[];
}
