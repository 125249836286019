import React from 'react';
import { Tooltip } from 'wix-ui-tpa/Tooltip';

import { withGeneralData } from '../../contexts/GeneralDataProvider/withGeneralData';
import { IGeneralDataContext } from '../../contexts/GeneralDataProvider/GeneralDataContext';
import { Chevron } from './Chevron';

import styles from './Accordion.st.css';

export interface IAccordionProps {
  title: React.ReactNode;
  tooltipText: string;
  prefixIcon?: React.ReactNode;
  'data-hook'?: string;
  opened?: boolean;
  className?: string;
}

export type IInnerAccordion = IAccordionProps & IGeneralDataContext;

export interface IAccordionState {
  opened: boolean;
}

class _Accordion extends React.PureComponent<IInnerAccordion, IAccordionState> {
  state = {
    opened: false,
  };

  componentDidMount() {
    if (this.props.opened) {
      this.setState({ opened: true });
    }
  }

  toggleOpen = () => {
    this.setState({ opened: !this.state.opened });
  };

  render() {
    const { formFactor, ...rest } = this.props;
    const mobile = formFactor === 'Mobile';

    return (
      <section {...styles('root', { opened: this.state.opened, mobile }, rest)}>
        <div
          className={`${styles.titleBar} ${this.props.className}`}
          onClick={this.toggleOpen}
          data-hook={this.props['data-hook']}
        >
          {!!this.props.prefixIcon && (
            <Tooltip
              content={this.props.tooltipText}
              placement="top"
              appendTo="window"
              maxWidth={250}
            >
              <span className={styles.prefixIcon}>{this.props.prefixIcon}</span>
            </Tooltip>
          )}

          <h3 className={styles.title}>{this.props.title}</h3>

          <span className={styles.icon}>
            <Chevron />
          </span>
        </div>

        {this.state.opened && (
          <div className={styles.content}>{this.props.children}</div>
        )}
      </section>
    );
  }
}

export const Accordion = withGeneralData<IAccordionProps>(_Accordion);
