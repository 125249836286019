import {
  IUserType,
  UserState,
} from '../../../../../../contexts/User/UserContext';
import { ParticipantState } from '@wix/ambassador-challenge-service-web/types';

interface IResultProps {
  disabled?: boolean;
  href?: string;
  target?: string;
  rel?: string;
}

interface IGetButtonPropsArgs {
  inviteLink: string;
  disabled: boolean;
  userType: IUserType;
  isMemberFlowExperimentEnabled: boolean;
}

export function getJoinButtonProps({
  inviteLink,
  userType,
  disabled,
  isMemberFlowExperimentEnabled,
}: IGetButtonPropsArgs) {
  const resultProps: IResultProps = {};

  if (isMemberFlowExperimentEnabled) {
    switch (userType) {
      case UserState.VISITOR:
      case UserState.MEMBER:
      case ParticipantState.INVITED:
      case ParticipantState.PAYMENT_REQUESTED:
      case ParticipantState.PAYMENT_STARTED:
      case ParticipantState.REMOVED:
      case ParticipantState.LEFT:
      case ParticipantState.JOIN_REJECTED:
      case ParticipantState.COMPLETED:
      case ParticipantState.FAILED:
      case ParticipantState.JOIN_REQUESTED:
        break;
      case ParticipantState.JOINED:
      case ParticipantState.RUNNING:
        resultProps.href = inviteLink;
        resultProps.target = '_blank';
        resultProps.rel = 'noopener noreferrer';
        break;
      default:
        resultProps.disabled = true;
    }
  } else {
    resultProps.href = inviteLink;
    resultProps.target = '_blank';
    resultProps.rel = 'noopener noreferrer';
  }
  resultProps.disabled = resultProps.disabled || disabled;

  return resultProps;
}
