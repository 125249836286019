import * as React from 'react';
import { applyProviders } from '../../../../../services/applyProviders';
import { withChallengeData } from '../../../../../contexts/ChallengeDataProvider/withChallengeData';
import { translate } from 'react-i18next';
import { withPaidPlans } from '../../../../../contexts/PaidPlans/paidPlansContext';
import { withUser } from '../../../../../contexts/User/withUser';
import { RadioGroupContainer } from '../RadioGroup/RadioGroup';
import { IPricingProps, SelectedPaymentOption } from './interfaces';
import { PageSection } from '../../../../../components-shared/PageSection';
import { SinglePricingOption } from '../SinglePricingOption/SinglePricingOption';
import { getPricingOptions } from './subscriptionTitle/getPricingOptions';
import { getChallengeEligiblePaidPlans } from './subscriptionTitle/getChallengeEligiblePaidPlans';
import {
  FREE_CHALLENGE_PRICING,
  ONLY_ONE_TIME_PAYMENT_PRICING,
} from './constants';
import userTypeHandlers from '../../../../../contexts/User/helpers/userTypeHandlers';
import {
  getChallengePaidPlans,
  serverPricingToClientPriceAndCurrencyString,
} from '@wix/challenges-web-library/dist/src';
import { getPaidPlanName } from './subscriptionTitle/getPaidPlanName';
import { getPaidPlanStartPrice } from './subscriptionTitle/getPaidPlanPrice';

export const Pricing: React.FC<Pick<
  IPricingProps,
  'selectedPaymentOption' | 'onPaymentSelected' | 'className' | 'disabled'
>> = applyProviders(
  (props: IPricingProps) => {
    const {
      t,
      challengeData: { challenge },
      userPaidPlans,
      eligiblePlans,
      userType,
      className,
      disabled,
    } = props;

    if (userTypeHandlers.isJoinedAlready(userType)) {
      return null;
    }

    // eligible means paid
    const challengeEligiblePaidPlans = getChallengeEligiblePaidPlans(
      eligiblePlans,
      userPaidPlans,
      challenge.id,
    );

    if (challengeEligiblePaidPlans.length) {
      if (props.selectedPaymentOption !== SelectedPaymentOption.PaidPlans) {
        props.onPaymentSelected(SelectedPaymentOption.PaidPlans);
      }

      return (
        <PageSection
          className={className}
          title={t('challenge.page.pricing-options.title-for-free')}
        >
          <SinglePricingOption>
            {t('pricing.payment-option.subscription.already-included')}
          </SinglePricingOption>
        </PageSection>
      );
    }

    const pricingOptions = getPricingOptions(challenge, userPaidPlans, t);
    // free challenge case
    if (!pricingOptions.length) {
      return (
        <PageSection
          dataHook={FREE_CHALLENGE_PRICING}
          className={className}
          title={t('challenge.page.pricing-options.title-for-free')}
        >
          <SinglePricingOption>
            {t(`challenge.page.pricing-options.free`)}
          </SinglePricingOption>
        </PageSection>
      );
    }

    if (!props.selectedPaymentOption) {
      props.onPaymentSelected(pricingOptions[0].value as any);
    }

    if (pricingOptions.length > 1) {
      return (
        <PageSection
          className={className}
          title={t('challenge.page.pricing-options.title-for-free')}
        >
          <RadioGroupContainer
            onSelect={(option) => {
              props.onPaymentSelected(option.value);
            }}
            selectedValue={props.selectedPaymentOption}
            options={pricingOptions}
            isDisabled={disabled || pricingOptions.length === 1}
          />
        </PageSection>
      );
    }

    if (pricingOptions[0].value === SelectedPaymentOption.PaidPlans) {
      const challengePaidPlans = getChallengePaidPlans(
        challenge.id,
        userPaidPlans,
      );
      const paidPlanName = getPaidPlanName(challengePaidPlans, t);
      const paidPlanStartPrice = getPaidPlanStartPrice(challengePaidPlans, t);
      const paidPlanOptionTitle = `${paidPlanName}, ${paidPlanStartPrice.toLocaleLowerCase()}`;

      return (
        <PageSection
          className={className}
          title={t('challenge.page.pricing-options.title-for-free')}
        >
          <SinglePricingOption>{paidPlanOptionTitle}</SinglePricingOption>
        </PageSection>
      );
    }

    if (pricingOptions[0].value === SelectedPaymentOption.SinglePayment) {
      return (
        <PageSection
          className={className}
          dataHook={ONLY_ONE_TIME_PAYMENT_PRICING}
          title={t('challenge.page.pricing-options.title-for-free')}
        >
          <SinglePricingOption>
            {serverPricingToClientPriceAndCurrencyString(
              challenge.settings.pricing as any,
            )}
          </SinglePricingOption>
        </PageSection>
      );
    }
  },
  [withChallengeData, withPaidPlans, withUser, translate()],
);
