import * as React from 'react';
import { TranslateFunction } from '@wix/challenges-web-library/dist/src';
import { ButtonNames } from '../../../../../contexts/BI/interfaces';
import { IUserType, UserState } from '../../../../../contexts/User/UserContext';

import { Button } from 'wix-ui-tpa/Button';
import styles from './JoinButton.st.css';
import { SelectedPaymentOption } from '../Pricing/interfaces';
import { ParticipantState } from '@wix/ambassador-challenge-service-web/types';
import {
  JoinFunction,
  CancelJoinRequestFunction,
} from '../../../../../contexts/User/userContextInterface';
import { getJoinButtonText } from './helpers/getJoinButtonText';
import { getJoinClickHandler } from './helpers/getJoinClickHandler';
import { getJoinBIButtonName } from './helpers/getJoinBIButtonName';
import { getJoinButtonProps } from './helpers/getJoinButtonProps';

export interface IJoinButtonProps {
  t: TranslateFunction;
  id?: string;
  dataHook?: string;
  className?: string;
  disabled: boolean;
  userType: IUserType;
  isAvailableForJoinImmediately: boolean;
  isMobile: boolean;
  buttonState: any;
  join: JoinFunction;
  cancelJoinRequest?: CancelJoinRequestFunction;
  inviteLink: string;
  paymentOption: SelectedPaymentOption | null;
  isMemberFlowExperimentEnabled: boolean;
  startDate?: string;
  preJoinValidation?(id: string): string | undefined;
  memberWebAppButtonClick?(props: any): Promise<any>;
}

export const JoinButton: React.FC<IJoinButtonProps> = ({
  t,
  dataHook,
  className,
  disabled,
  userType,
  isAvailableForJoinImmediately,
  isMobile,
  paymentOption,
  buttonState,
  join,
  inviteLink,
  isMemberFlowExperimentEnabled,
  startDate,
  preJoinValidation,
  cancelJoinRequest,
  id,
  memberWebAppButtonClick,
}) => {
  const resultProps = getJoinButtonProps({
    inviteLink,
    disabled,
    isMemberFlowExperimentEnabled,
    userType,
  });
  const resultContent = getJoinButtonText({
    t,
    isAvailableForJoinImmediately,
    isMemberFlowExperimentEnabled,
    userType,
  });
  const biButtonName = getJoinBIButtonName({
    isMemberFlowExperimentEnabled,
    userType,
  });
  const onClickHandler = getJoinClickHandler({
    isMemberFlowExperimentEnabled,
    joinHandler: async () => {
      await join(paymentOption, startDate);
    },
    cancelHandler: async () => {
      await cancelJoinRequest();
    },
    userType,
  });

  return (
    <div
      {...styles(
        'root',
        {
          mobile: isMobile,
          disabled: resultProps.disabled,
          buttonState,
        },
        {
          className,
        },
      )}
    >
      <Button
        {...resultProps}
        as={'a'}
        className={styles.button}
        fullWidth={isMobile}
        data-hook={dataHook}
        onClick={async () => {
          const validationError = preJoinValidation && preJoinValidation(id);
          if (validationError) {
            console.warn('[challenges] Join validation error', validationError);
            return;
          }

          if (memberWebAppButtonClick) {
            await memberWebAppButtonClick({
              buttonName: biButtonName,
            });
          }
          onClickHandler();
        }}
      >
        {resultContent}
      </Button>
    </div>
  );
};
