import * as React from 'react';
import {
  IChallengeJoinRestriction,
  IChallengePageProps,
  IChallengePageSettingsValues,
  IChallengePageState,
} from './interfaces';

import { IWixSDKContext } from 'yoshi-flow-editor-runtime';

import {
  ParticipantStepState,
  V1Challenge,
  V1ChallengeState,
  V1DurationUnit,
  V1ParticipantStep,
} from '@wix/ambassador-challenge-service-web/types';
import { getCountPrefix } from '../../../selectors/langCount';

import { IStepFeedbackSend, StepFeedback } from './components/StepFeedback';
import { Spinner } from '../../../components-shared/Spinner';
import UserBox from '../../../components-shared/UserBox';
import { WeeksSelector } from '../../../components-shared/WeeksSelector';
import { Modal } from '../../../components-shared/Modal';
import { LeaveTheChallenge } from './components/LeaveTheChallenge';
import { rceUtils } from '../../../components-shared/rce/rceUtils';
import {
  Button,
  PRIORITY as ButtonPRIORITY,
  SIZE as ButtonSIZE,
} from 'wix-ui-tpa/Button';
import {
  ALIGNMENT as TabsALIGNMENT,
  SKIN as TabsSKIN,
  Tabs,
  VARIANT as TabsVARIANT,
} from 'wix-ui-tpa/Tabs';
import { WixRicosViewer } from '@wix/ricos-viewer';
import groupBy from 'lodash/groupBy';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import * as locales from 'date-fns/locale';

import '../../../components-shared/rce/rce.global.scss';
import styles from './WidgetComponentForParticipant.st.css';
import { StepsAccordion } from '../../../components-shared/StepsAccordion';
import { ChallengeDetails } from '../../../components-shared/ChallengeDetails';

import { ReactComponent as CompletedIcon } from '../../../assets/icons/done.svg';
import { ReactComponent as LockedIcon } from '../../../assets/icons/locked.svg';
import { ReactComponent as RunningIcon } from '../../../assets/icons/running.svg';
import { AwardsBadges } from '../../../components-shared/Badges';
import { RewardInfo } from '../../../components-shared/RewardInfo';
import { Challenges } from '../../../editor/types/Experiments';
import { Notification } from '../../../components-shared/Notification';
import { getRightDateFromBackend } from '../../../selectors/dates';
import { Dots } from './components/Dots';
import { ButtonNames, TabNames } from '../../../contexts/BI/interfaces';
import { VIEW_MODE } from '../../../config/constants';

const MissedIconCSS = () => {
  return (
    <div className={styles.missedIcon}>
      <span className={styles.missedIconCircle}></span>
    </div>
  );
};

const STEP_INDICATOR = {
  [ParticipantStepState.COMPLETED]: <CompletedIcon />,
  [ParticipantStepState.OVERDUE]: <MissedIconCSS />,
  [ParticipantStepState.PENDING]: <LockedIcon />,
  [ParticipantStepState.RUNNING]: <RunningIcon />,
};

const STEP_TOOLTIP_KEY = {
  [ParticipantStepState.COMPLETED]: 'step.tooltip.completed',
  [ParticipantStepState.OVERDUE]: 'step.tooltip.missed',
  [ParticipantStepState.PENDING]: 'step.tooltip.pending',
  [ParticipantStepState.RUNNING]: 'step.tooltip.running',
};

export default class ChallengePage extends React.Component<
  IChallengePageProps & IWixSDKContext,
  IChallengePageState
> {
  static displayName = 'ChallengePage';
  public readonly pageName = 'challenge-page';
  public readonly translationName = 'challenge.page';

  constructor(props: IChallengePageProps & IWixSDKContext) {
    super(props);

    this.state = {
      isError: null,
      startDate: null,
      selectedTabIndex: 0,
      currentStep: null,
      isFeedbackModalOpened: false,
      isLeaveModalOpened: false,
      selectedPaymentOption: null,
      challengeJoinRestrictions: [],
    };

    this.checkRestrictions();
  }

  componentDidUpdate(prevProps) {
    const t = this.props.t;

    if (
      prevProps.isResolveStepRequestInProgress &&
      !this.props.isResolveStepRequestInProgress
    ) {
      if (!this.props.resolveStepError) {
        this.props.showToast(t('toast.step-completed'));
        this.props.updateParticipantStepStatus(
          this.props.participantSteps?.steps,
          this.state.currentStep.id,
          ParticipantStepState.COMPLETED,
        );
      } else {
        // ...
      }
    }
  }

  checkRestrictions() {
    const { challenge } = this.props.challengeData;

    if (
      challenge?.transitions['0'] &&
      challenge?.transitions['0'].state === V1ChallengeState.FINISHED
    ) {
      this.state.challengeJoinRestrictions.push(
        IChallengeJoinRestriction.FINISHED,
      ); // ! Called from constructor.
    }
  }

  onStepResolve = async () => {
    const challengeStepIndividualSettings = this.state?.currentStep?.source
      ?.settings?.general?.individual;
    const isFeedbackFormRequired =
      challengeStepIndividualSettings &&
      challengeStepIndividualSettings.confirmationRequired &&
      Object.keys(challengeStepIndividualSettings?.feedbackSettings.length);

    if (isFeedbackFormRequired) {
      this.setState({
        isFeedbackModalOpened: true,
      });
    } else {
      this.resolveStep();
    }
  };

  resolveStep(data: IStepFeedbackSend = { complexity: null, comment: null }) {
    this.setState(
      {
        isFeedbackModalOpened: false,
      },
      async () => {
        const stepId = this.state?.currentStep?.id;

        if (stepId) {
          const { complexity, comment } = data;

          await this.props.resolveStep(stepId, complexity, comment);
        } else {
          console.error("Can't find step for resolve.");
        }
      },
    );
  }

  render() {
    const { challengeData, isLoading, settings, ...rest } = this.props;

    return challengeData && challengeData.challenge ? (
      isLoading ? (
        <Spinner data-hook={`${this.pageName}-spinner`} />
      ) : (
        <main
          data-hook={this.pageName}
          {...styles(
            'root',
            {
              mobile: this.isMobile(),
              headerTextAlignment: settings.headerTextAlignment,
              imageRatio: settings.imageRatio,
              cropSelection: settings.cropSelection,
              contentTextAlignment: settings.contentTextAlignment,
              buttonState: settings.buttonState,
            },
            rest,
          )}
        >
          {this.renderWithChallenge()}
        </main>
      )
    ) : null;
  }

  renderWithChallenge() {
    return (
      <>
        {this.renderNotifications()}
        {this.renderHeader()}
        {this.state.selectedTabIndex === 0 ? this.renderWeeksSelector() : null}
        {this.state.selectedTabIndex === 0 ? this.renderStepsList() : null}
        {this.state.selectedTabIndex === 0 &&
        !this.props.isParticipantStepsLoading &&
        this.props.participantSteps?.steps?.length
          ? this.renderWeeksSelector()
          : null}
        {this.state.selectedTabIndex === 0 ? this.renderFeedbackModal() : null}
        {this.state.selectedTabIndex === 1 ? this.renderOverview() : null}
        {this.renderLeaveModal()}
      </>
    );
  }

  renderOverview() {
    const { challenge } = this.props.challengeData;
    const { enabled, t } = this.props;
    const isBadgesEnabled = enabled(Challenges.showBadges);

    return (
      <div className={styles.overview}>
        <h3 className={styles.overviewTitle}>
          {t('challenge.overview.about-title')}
        </h3>
        <ChallengeDetails
          isMobile={this.props.formFactor === 'Mobile'}
          className={styles.challengeDetails}
          details={
            this.props.challengeData?.challenge?.settings?.description?.details
          }
        />

        {isBadgesEnabled && (
          <>
            <AwardsBadges
              title={
                <h3 className={styles.overviewTitle}>
                  {t('challenge.overview.reward-title')}
                </h3>
              }
              rewards={challenge.settings.rewards}
              instance={this.props.instance}
            />
            <RewardInfo
              className={styles.overviewRewards}
              rewards={challenge.settings.rewards}
            />
          </>
        )}

        <h3 className={styles.overviewTitle}>
          {t('challenge.overview.owner-title')}
        </h3>
        <UserBox
          className={styles.overviewAuthor}
          imageUrl={challenge.owners[0].imageUrl}
          fullName={challenge.owners[0].fullName}
          dataHook={`${this.pageName}-author`}
          alignment={this.props.settings.headerTextAlignment}
        />
      </div>
    );
  }

  renderNotifications() {
    const { t } = this.props;
    const { challengeJoinRestrictions } = this.state;
    const firstRestriction =
      challengeJoinRestrictions && challengeJoinRestrictions[0];
    let restrictionContent = '';

    switch (firstRestriction) {
      case IChallengeJoinRestriction.FINISHED:
        restrictionContent = t('challenge.page.restrictions.finished-already');
        break;
      default:
    }

    return firstRestriction ? (
      <Notification
        className={styles.topNotification}
        data-hook={`${this.pageName}-top-notifications`}
        alignment={this.props.settings.headerTextAlignment}
        withErrorIcon={true}
        content={restrictionContent}
      />
    ) : null;
  }

  renderHeader() {
    const {
      t,
      lng,
      challengeData: { challenge },
      inviteLink,
      settings,
      enabled,
    } = this.props;
    const isBadgesEnabled = enabled(Challenges.showBadges);

    const {
      showDuration,
      showParticipants,
      showSteps,
      overviewItemsCount,
      stepsCount,
    } = this.getBaseViewOptions(challenge, settings);
    const durationLangPrefix = getCountPrefix(
      challenge.settings.timelineType.flexible &&
        challenge.settings.timelineType.flexible.duration.value,
      lng,
    );
    const stepsCountPrefix = getCountPrefix(stepsCount, lng);
    const participantsCountPrefix = getCountPrefix(
      challenge.participantsSummary.participantsNumber,
      lng,
    );

    return (
      <header className={styles.header}>
        <div className={styles.headerInner}>
          <h1
            className={styles.headerTitle}
            data-hook={`${this.pageName}-title`}
          >
            {challenge.settings.description.title}
          </h1>

          <ul
            style={{
              gridTemplateColumns: `repeat(${overviewItemsCount}, 135px)`,
            }}
            className={styles.detailsList}
            data-hook={`${this.pageName}-details-list`}
          >
            {showDuration
              ? this.renderDetailsItem(
                  challenge.settings.timelineType.flexible.duration.value,
                  challenge.settings.timelineType.flexible.duration.unit ===
                    V1DurationUnit.DAYS
                    ? t(
                        `${this.translationName}.details.days${durationLangPrefix}`,
                      )
                    : t(
                        `${this.translationName}.details.weeks${durationLangPrefix}`,
                      ),
                  `${this.pageName}-details-item-duration`,
                )
              : null}

            {showSteps
              ? this.renderDetailsItem(
                  stepsCount,
                  t(`${this.translationName}.details.steps${stepsCountPrefix}`),
                  `${this.pageName}-details-item-steps`,
                )
              : null}

            {showParticipants
              ? this.renderDetailsItem(
                  challenge.participantsSummary.participantsNumber,
                  t(
                    `${this.translationName}.details.participants${participantsCountPrefix}`,
                  ),
                  `${this.pageName}-details-item-participants`,
                )
              : null}
          </ul>

          {isBadgesEnabled && (
            <RewardInfo
              className={styles.headerRewards}
              rewards={challenge.settings.rewards}
              icon={true}
            />
          )}

          <div className={styles.headerDotsMenu}>
            <Dots
              leaveTheChallengeText={t(
                `${this.translationName}.dots.leave-the-channel`,
              )}
              leaveTheChannelCallback={() => {
                this.setState(
                  {
                    isLeaveModalOpened: true,
                  },
                  async () => {
                    await this.props.memberWebAppButtonClick({
                      buttonName: ButtonNames.LeaveTheChallenge,
                    });
                  },
                );
              }}
              onOpenMenu={async () => {
                await this.props.memberWebAppButtonClick({
                  buttonName: ButtonNames.OpenDotsMenu,
                });
              }}
            />
          </div>

          {settings.displayOneApp && (
            <Button
              className={styles.headerCTA}
              data-hook={`${this.pageName}-header-cta`}
              priority={ButtonPRIORITY.basicSecondary}
              size={ButtonSIZE.medium}
              as="a"
              href={inviteLink}
              onClick={async () => {
                await this.props.memberWebAppButtonClick({
                  buttonName: ButtonNames.GoToOneApp,
                });
              }}
              {...({
                target: '_blank',
                rel: 'noopener noreferrer',
              } as any)}
            >
              {t('challenge.page.join-via', {
                wixApp: t('challenge.wix-app'),
              })}
            </Button>
          )}
        </div>
        {this.renderTabs()}
      </header>
    );
  }

  renderDetailsItem(
    value: string | number,
    description: string,
    dataHook: string,
  ) {
    return (
      <li className={styles.detailsListItem} data-hook={dataHook}>
        <span className={styles.detailsListItemValue}>{value}</span>
        <span className={styles.detailsListItemDescription}>{description}</span>
      </li>
    );
  }

  renderTabs() {
    const { t, settings } = this.props;
    const isMobile = this.isMobile();

    return (
      <Tabs
        data-hook={`${this.pageName}-tabs`}
        className={styles.tabs}
        activeTabIndex={this.state.selectedTabIndex}
        skin={TabsSKIN.clear} // it has strange color in source code with opacity
        alignment={
          isMobile
            ? TabsALIGNMENT.center
            : settings.headerTextAlignment === 'right'
            ? TabsALIGNMENT.right
            : TabsALIGNMENT.left
        }
        variant={isMobile ? TabsVARIANT.fullWidth : TabsVARIANT.fit}
        items={[
          {
            title: t(`${this.translationName}.tabs.schedule`),
          },
          {
            title: t(`${this.translationName}.tabs.overview`),
          },
        ]}
        onTabClick={(selectedTabIndex) => {
          this.setState(
            {
              selectedTabIndex,
            },
            async () => {
              await this.props.tabOpen({
                tabName:
                  selectedTabIndex === 0
                    ? TabNames.ChallengeParticipantSchedule
                    : TabNames.ChallengeParticipantOverview,
              });
            },
          );
        }}
      />
    );
  }

  renderWeeksSelector() {
    const {
      t,
      lng,
      participant,
      updateParticipantSteps,
      viewMode,
    } = this.props;

    // todo: this is default values for the editor, need to create Ambassador mocks and move it there
    const start =
      participant?.dateFrame?.start ||
      (viewMode === VIEW_MODE.Editor ? '2020-01-01' : null);
    const finish =
      participant?.dateFrame?.finish ||
      (viewMode === VIEW_MODE.Editor ? '2021-01-01' : null);

    return (
      <div className={styles.weeksSelector}>
        <WeeksSelector
          t={t}
          locale={lng}
          min={start}
          max={finish}
          onChange={(range) => {
            updateParticipantSteps(range.from);
          }}
        />
      </div>
    );
  }

  renderStepsList() {
    const {
      t,
      challengeData: { challenge },
      participantSteps: { steps },
      isParticipantStepsLoading,
    } = this.props;

    const stepsByStartDate = groupBy(steps, (step: V1ParticipantStep) => {
      return step.dateFrame.start;
    });
    const isChallengeFinished =
      challenge?.transitions['0'] &&
      challenge?.transitions['0'].state === V1ChallengeState.FINISHED;

    let isFirstRunningStepWasOpened = false;

    return (
      <div
        className={styles.stepsList}
        data-hook={`${this.pageName}-steps-list`}
      >
        {isParticipantStepsLoading ? (
          <Spinner />
        ) : (
          Object.keys(stepsByStartDate).map((startDate) => (
            <>
              <h2 className={styles.stepsGroupTitle}>
                {format(getRightDateFromBackend(startDate), 'MMMM dd')}
              </h2>

              {stepsByStartDate[startDate].map(
                (step: V1ParticipantStep, ind: number) => {
                  const isCompleteButtonShown =
                    step.transitions['0'] &&
                    (step.transitions['0'].state ===
                      ParticipantStepState.RUNNING ||
                      (step.transitions['0'].state ===
                        ParticipantStepState.PENDING &&
                        isSameDay(
                          getRightDateFromBackend(step.dateFrame.start),
                          new Date(),
                        )));
                  const isCompleteButtonDisabled = this.props
                    .isResolveStepRequestInProgress;
                  const isStepOpened =
                    isCompleteButtonShown && !isFirstRunningStepWasOpened;

                  if (isStepOpened) {
                    isFirstRunningStepWasOpened = true;
                  }

                  const formattedStepDate = format(
                    new Date(step.dateFrame.start),
                    'MMM dd, yyyy',
                    { locale: locales[this.props.lng] },
                  );

                  return (
                    <StepsAccordion
                      tooltipText={t(
                        step.transitions['0'].state ===
                          ParticipantStepState.PENDING &&
                          isSameDay(new Date(step.dateFrame.start), new Date())
                          ? STEP_TOOLTIP_KEY.RUNNING
                          : STEP_TOOLTIP_KEY[step.transitions[0].state],
                      )}
                      className={
                        step.transitions[0].state ===
                          ParticipantStepState.PENDING && !isCompleteButtonShown
                          ? styles.lockedStep
                          : ''
                      }
                      key={`step-${ind}`}
                      prefixIcon={
                        isCompleteButtonShown
                          ? STEP_INDICATOR[ParticipantStepState.RUNNING]
                          : STEP_INDICATOR[step.transitions[0].state]
                      }
                      title={step.source.settings.general.description.title}
                      opened={isStepOpened}
                    >
                      {step.transitions[0].state ===
                        ParticipantStepState.PENDING &&
                        !isCompleteButtonShown && (
                          <p className={styles.stepUnavailable}>
                            {t('challenge.page.step-unavailable.warning', {
                              date: formattedStepDate,
                            })}
                          </p>
                        )}
                      <WixRicosViewer
                        cssOverride={{
                          bgColor: 'transparent',
                        }}
                        theme={{
                          palette: this.props.host?.style?.siteColors,
                        }}
                        plugins={rceUtils.PLUGINS}
                        content={JSON.parse(
                          step.source.settings.general.description.details,
                        )}
                        biSettings={{ consumer: 'challenges-ooi-viewer' }}
                      />
                      {!isChallengeFinished &&
                      (isCompleteButtonShown ||
                        step.transitions[0].state ===
                          ParticipantStepState.OVERDUE) ? (
                        <Button
                          className={styles.completeStepAction}
                          data-hook={`${this.pageName}-complete-step-action`}
                          disabled={isCompleteButtonDisabled}
                          priority={ButtonPRIORITY.basic}
                          size={ButtonSIZE.medium}
                          onClick={() => {
                            if (!isCompleteButtonDisabled) {
                              this.setState(
                                {
                                  currentStep: step,
                                },
                                async () => {
                                  await this.props.memberWebAppButtonClick({
                                    buttonName: ButtonNames.StepComplete,
                                  });
                                  await this.onStepResolve();
                                },
                              );
                            }
                          }}
                        >
                          {isCompleteButtonDisabled ? (
                            <Spinner role="element" />
                          ) : (
                            t(`${this.translationName}.steps.complete`)
                          )}
                        </Button>
                      ) : null}

                      {step.transitions[0].state ===
                        ParticipantStepState.COMPLETED && (
                        <p className={styles.stepCompleted}>
                          {t('challenge.page.step.completed')}
                        </p>
                      )}
                    </StepsAccordion>
                  );
                },
              )}
            </>
          ))
        )}
      </div>
    );
  }

  renderFeedbackModal() {
    const feedbackSettings = this.state?.currentStep?.source?.settings?.general
      ?.individual?.feedbackSettings;

    return (
      <Modal
        opened={this.state.isFeedbackModalOpened}
        className={styles.stepModal}
        contentClassName={styles.modalContent}
        onClose={() => {
          this.setState({
            isFeedbackModalOpened: false,
          });
        }}
      >
        <StepFeedback
          isMobile={this.isMobile()}
          t={this.props.t}
          feedbackSettings={feedbackSettings}
          onSend={async (data) => {
            this.resolveStep(data);
          }}
          onCancel={() => {
            this.setState({
              isFeedbackModalOpened: false,
            });
          }}
        />
      </Modal>
    );
  }

  renderLeaveModal() {
    const {
      t,
      participant,
      leaveTheChallenge,
      memberWebAppButtonClick,
    } = this.props;

    return (
      <Modal
        opened={this.state.isLeaveModalOpened}
        onClose={() => {
          this.setState({
            isLeaveModalOpened: false,
          });
        }}
      >
        <LeaveTheChallenge
          t={t}
          isMobile={this.isMobile()}
          onLeave={async () => {
            await memberWebAppButtonClick({
              buttonName: ButtonNames.LeaveTheChallengeAtModal,
            });

            if (participant?.id) {
              await leaveTheChallenge(participant.id);
            }
          }}
          onCancel={() => {
            this.setState(
              {
                isLeaveModalOpened: false,
              },
              async () => {
                await memberWebAppButtonClick({
                  buttonName: ButtonNames.CancelLeaveTheChallengeModal,
                });
              },
            );
          }}
        />
      </Modal>
    );
  }

  isMobile() {
    return this.props.formFactor === 'Mobile';
  }

  getBaseViewOptions(
    challenge: V1Challenge,
    settings: IChallengePageSettingsValues,
  ) {
    const stepsCount = challenge.stepsSummary.stepsNumber;
    const showSteps = !!stepsCount;
    const showDuration = !!challenge.settings.timelineType.flexible;
    const showParticipants = !!challenge.participantsSummary.participantsNumber;

    const overviewItemsCount =
      Number(showSteps) + Number(showDuration) + Number(showParticipants);

    return {
      showSteps,
      stepsCount,
      showDuration,
      showParticipants,
      overviewItemsCount,
    };
  }
}
