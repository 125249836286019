import React from 'react';

import {
  RadioButton as CoreRadio,
  RadioButtonProps,
} from 'wix-ui-core/radio-button';

import styles from './RadioButton.st.css';
import { UnselectedIcon } from './Unselected';
import { SelectedIcon } from './Selected';

export interface IRadioButton extends RadioButtonProps {
  className?: string;
}

export const RadioButton: React.FC<IRadioButton> = (props) => {
  const { checked, disabled, className, ...rest } = props;

  return (
    <span className={className}>
      <CoreRadio
        {...styles(styles.icon, { checked, disabled }, rest)}
        checked={checked}
        uncheckedIcon={<UnselectedIcon />}
        checkedIcon={<SelectedIcon />}
        {...rest}
      />
    </span>
  );
};
