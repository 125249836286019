import * as React from 'react';
import { translate } from 'react-i18next';
import { ChallengeReward } from '@wix/ambassador-challenge-service-web/types';

import { loadBadges } from '../../services/BadgesService';

import styles from './Badges.st.css';
import { Spinner } from '../Spinner';

export interface IBadge {
  id: string;
  title: string;
  description: string;
  backgroundColor: string;
  textColor: string;
  icon: string;
  roleId: string;
  hasPermissions: boolean;
  slug: string;
  createDate: string;
  updateDate: string;
}

export interface IBadgesProps {
  t(key: string): string;
  rewards: ChallengeReward[];
  instance: string;
  title?: React.ReactNode;
}

export interface IBadgesState {
  badges: IBadge[];
  loading: boolean;
}

class _Badges extends React.PureComponent<IBadgesProps, IBadgesState> {
  static displayName = 'AwardsBadges';

  state = {
    badges: [],
    loading: false,
  };

  async componentDidMount() {
    const challengeRewardsIds = this.props.rewards[0]?.badgeIds || null;
    if (!challengeRewardsIds) {
      return;
    }

    this.setState({ loading: true });

    let badgesRes;

    try {
      badgesRes = await loadBadges(this.props.instance, challengeRewardsIds);
      const filteredBadges = badgesRes.badges.filter((badge) =>
        challengeRewardsIds.includes(badge.id),
      );

      this.setState({ badges: filteredBadges, loading: false });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  }

  render() {
    return (
      <div className={styles.root}>
        {!!this.state.badges.length && this.props.title}
        {this.state.loading ? (
          <Spinner role="element" className={styles.spinner} />
        ) : (
          <div className={styles.badgeList}>
            {this.state.badges.map((badge) => (
              <span
                className={styles.badge}
                style={{
                  color: badge.textColor,
                  background: badge.backgroundColor,
                }}
              >
                {!!badge.icon && (
                  <img
                    className={styles.image}
                    src={badge.icon}
                    alt={badge.slug}
                  />
                )}
                <span className={styles.text}>{badge.title}</span>
              </span>
            ))}
          </div>
        )}
        {}
      </div>
    );
  }
}

export const AwardsBadges: React.FC<Omit<IBadgesProps, 't'>> = translate()(
  _Badges,
);
