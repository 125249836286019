import * as React from 'react';
import { Tooltip, TooltipProps } from 'wix-ui-tpa/Tooltip';
import { isCorrectStringDate, getDateFromString } from '../../selectors/dates';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import isAfter from 'date-fns/isAfter';

import styles from './DateInput.st.css';
import addDays from 'date-fns/addDays';
import { TextAlignment } from '../../components/ChallengesPage/Settings/challengeSettings/challengeSettings.types';

export interface IDateInputProps {
  className?: string;
  defaultValue?: any;
  errorMessage?: string;
  onChange?(isError: boolean, value: string): void;
  firstTouch?: boolean;
  textAlignment: TextAlignment;
}

export interface IDateInputState {
  value: string;
  isError: boolean;
}

export default class DateInput extends React.Component<
  IDateInputProps,
  IDateInputState
> {
  constructor(props: IDateInputProps) {
    super(props);

    this.state = {
      value: this.props.defaultValue,
      isError: false,
    };
  }

  componentDidUpdate(prevProps: IDateInputProps): void {
    if (!prevProps.firstTouch && this.props.firstTouch) {
      this.setState({
        isError: true,
      });
    }
  }

  checkSymbol(value, rejectSymbol) {
    const getSymbol = (ind) => parseInt(value[ind], 10);
    const hasSymbol = (ind) => !isNaN(getSymbol(ind));

    if (hasSymbol(0) && getSymbol(0) > 3) {
      rejectSymbol();
    }

    if (
      hasSymbol(0) &&
      hasSymbol(1) &&
      getSymbol(0) === 3 &&
      getSymbol(1) > 1
    ) {
      rejectSymbol();
    }

    if (hasSymbol(2) && getSymbol(2) > 1) {
      rejectSymbol();
    }

    if (
      hasSymbol(2) &&
      hasSymbol(3) &&
      getSymbol(2) === 1 &&
      getSymbol(3) > 2
    ) {
      rejectSymbol();
    }

    if (hasSymbol(4) && getSymbol(4) !== 2) {
      rejectSymbol();
    }
  }

  onChange = (e) => {
    const target = e.currentTarget;
    const value = target.value || '';
    const newValue = value.replace(/[^0-9]/g, '').substr(0, 8);
    const isBackspace =
      (e.nativeEvent as any).inputType === 'deleteContentBackward';
    const dayOrMonth = (index) => index % 2 === 1 && index < 4;

    let selectionStart = target.selectionStart;
    let selectionEnd = target.selectionEnd;
    let isRejected = false;

    const rejectSymbol = () => {
      if (!isBackspace) {
        e.preventDefault();
        isRejected = true;
      }
    };

    if (newValue.length < 6) {
      this.checkSymbol(newValue, rejectSymbol);
    }

    if (selectionStart === 5) {
      selectionStart += 2;
      selectionEnd += 2;
    } else if (selectionStart === 2) {
      selectionStart += 1;
      selectionEnd += 1;
    }

    if (!isRejected) {
      this.setState(
        {
          value: !isBackspace
            ? newValue
                .split('')
                .map((v, i) => (dayOrMonth(i) ? `${v}/` : v))
                .join('')
            : value,
        },
        () => {
          if (!isNaN(selectionStart)) {
            setTimeout(() => {
              target.setSelectionRange(selectionStart, selectionEnd);
            }, 0);
          }
        },
      );
    }
  };

  onBlur = () => {
    const { onChange } = this.props;
    const value = this.state.value;
    const isCorrectDate = isCorrectStringDate(value);
    const isAvailableDate = isCorrectDate
      ? isAfter(getDateFromString(value), addDays(new Date(), -1))
      : false;
    const isError = !isAvailableDate;

    this.setState(
      {
        isError,
      },
      () => {
        if (onChange) {
          onChange(isError, value);
        }
      },
    );
  };

  render() {
    const { errorMessage } = this.props;
    const DateInputControl = this.renderDateInput();

    return this.state.isError ? (
      <Tooltip
        content={errorMessage}
        skin={'error' as TooltipProps['skin']}
        placement="top-end"
        appendTo="window"
      >
        {DateInputControl}
      </Tooltip>
    ) : (
      DateInputControl
    );
  }

  renderDateInput() {
    const { className, textAlignment } = this.props;

    return (
      <div
        {...styles(
          'root',
          {
            error: this.state.isError,
            textAlignment,
          },
          {
            className,
          },
        )}
      >
        <input
          type="text"
          className={styles.input}
          placeholder="dd/mm/yyyy"
          value={this.state.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
        {this.state.isError ? (
          <ErrorIcon key="0" className={styles.errorIcon} />
        ) : null}
      </div>
    );
  }
}
