import React from 'react';

export const SelectedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g>
            <rect width="8" height="8" x="4" y="4" fill="#000" rx="4"></rect>
            <circle
              cx="8"
              cy="8"
              r="7.5"
              stroke="#000"
              strokeWidth="1"
              opacity="0.6"
            ></circle>
          </g>
        </g>
      </g>
    </svg>
  );
};
