import React from 'react';
import styles from './Notification.st.css';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';

interface INotificationProps {
  content: string;
  alignment?: any;
  withErrorIcon?: boolean;
  dataHook?: string;
  className?: string;
  maxWidth?: number;
}

export const Notification: React.FC<INotificationProps> = (props) => {
  const { content, dataHook, withErrorIcon, alignment, ...rest } = props;

  return (
    <div
      {...styles(
        'root',
        {
          alignment,
        },
        { ...rest },
      )}
      data-hook={dataHook}
    >
      <div className={styles.inner}>
        {withErrorIcon ? <ErrorIcon className={styles.icon} /> : null}

        <span className={styles.content}>{content}</span>
      </div>
    </div>
  );
};
