import { pluginVideo } from 'wix-rich-content-plugin-video/dist/module.viewer.cjs';
import { pluginDivider } from 'wix-rich-content-plugin-divider/dist/module.viewer.cjs';
import { pluginImage } from 'wix-rich-content-plugin-image/dist/module.viewer.cjs';
import { pluginGallery } from 'wix-rich-content-plugin-gallery/dist/module.viewer.cjs';
import { pluginLink } from 'wix-rich-content-plugin-link/dist/module.viewer.cjs';

const PLUGINS = [
  pluginVideo(),
  pluginDivider(),
  pluginImage(),
  pluginGallery(),
  pluginLink(),
];

export const rceUtils = {
  PLUGINS,
};
