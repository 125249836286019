import * as React from 'react';
import {
  IChallengeJoinRestriction,
  IChallengePageProps,
  IChallengePageSettingsValues,
  IChallengePageState,
} from './interfaces';

import { IWixSDKContext } from 'yoshi-flow-editor-runtime';

import {
  IImageData,
  imageDataToMediaUrl,
  serverTimelineTypeToClientTimelineString,
} from '@wix/challenges-web-library/dist/src';
import {
  ParticipantState,
  V1Challenge,
  V1DurationUnit,
} from '@wix/ambassador-challenge-service-web/types';
import { getCountPrefix } from '../../../selectors/langCount';

import { JoinButton } from './components/JoinButton';
import { Spinner } from '../../../components-shared/Spinner';
import UserBox from '../../../components-shared/UserBox';
import DateInput from '../../../components-shared/DateInput';
import { Notification } from '../../../components-shared/Notification';

import styles from './WidgetComponent.st.css';
import { UserState } from '../../../contexts/User/UserContext';
import { ChallengeDetails } from '../../../components-shared/ChallengeDetails';
import { PageSection } from '../../../components-shared/PageSection';
import { Pricing } from './components/Pricing/Pricing';
import { RewardInfo } from '../../../components-shared/RewardInfo';
import { Challenges } from '../../../editor/types/Experiments';
import userTypeHandlers from '../../../contexts/User/helpers/userTypeHandlers';
import {
  isChallengeAlreadyStarted,
  isChallengePaid,
  isNeedToSelectStartDate,
  isPricingPickingForbidden,
} from '../../../selectors/challenges';
import { VIEW_MODE } from '../../../config/constants';

export enum JoinButtonIds {
  Top = 'Top',
  Bottom = 'Bottom',
}

export default class ChallengePage extends React.Component<
  IChallengePageProps & IWixSDKContext,
  IChallengePageState
> {
  static displayName = 'ChallengePage';
  public readonly pageName = 'challenge-page';
  public readonly translationName = 'challenge.page';
  private readonly footerRef = null;
  private readonly dateInputRef = null;

  constructor(props: IChallengePageProps & IWixSDKContext) {
    super(props);

    this.state = {
      selectedPaymentOption: null,
      isError: false,
      startDate: null, // todo: revert maybe: format(addDays(new Date(), 1), 'dd/MM/yyyy'),
      isStartDateFirstTouch: false, // some sort of hack, we need to set the error state for start date for the first attempt to join with empty value
      challengeJoinRestrictions: [],
    };

    this.footerRef = React.createRef();
    this.dateInputRef = React.createRef();
    this.scrollToFooter = this.scrollToFooter.bind(this);

    this.checkRestrictions();
  }

  checkRestrictions() {
    const { participant } = this.props;
    const participantsNumber = this.props.challengeData?.challenge
      ?.participantsSummary?.participantsNumber;
    const maxParticipants = this.props.challengeData?.challenge?.settings
      ?.accessRestrictions?.maxParticipants;

    if (
      !participant?.id &&
      participantsNumber &&
      maxParticipants &&
      !isNaN(participantsNumber) &&
      !isNaN(maxParticipants) &&
      participantsNumber >= maxParticipants
    ) {
      this.state.challengeJoinRestrictions.push(
        IChallengeJoinRestriction.MAX_PARTICIPANTS,
      ); // ! Called from constructor.
    }

    if (
      isChallengeAlreadyStarted(
        this.props.challengeData.challenge,
        participant?.transitions[0].state,
      )
    ) {
      this.state.challengeJoinRestrictions.push(
        IChallengeJoinRestriction.SPECIFIC_STARTED,
      ); // ! Called from constructor.
    }
  }

  /**
   For the flexible challenge with empty start date we need to set error state for the start date picker on join attempt.
   For the free challenge we need just to start join process both for the top and bottom buttons.
   For the paid challenge we need to scroll to bottom on click on the top button.
   */
  validation = (id: string): string | undefined => {
    const {
      challengeData: { challenge },
      userType,
    } = this.props;
    const { startDate } = this.state;

    if (isNeedToSelectStartDate(challenge, userType) && !startDate) {
      this.setState({
        isStartDateFirstTouch: true,
      });
      if (id === JoinButtonIds.Top) {
        this.scrollToFooter();
      }
      return 'Start day must be filled';
    }

    if (
      id === JoinButtonIds.Top &&
      (isNeedToSelectStartDate(challenge, userType) ||
        isChallengePaid(challenge))
    ) {
      this.scrollToFooter();
      return 'Top button just scrolls to footer';
    }
  };

  render() {
    const {
      challengeData,
      viewMode,
      isLoading,
      settings,
      ...rest
    } = this.props;

    return challengeData && challengeData.challenge ? (
      isLoading ? (
        <Spinner data-hook={`${this.pageName}-spinner`} />
      ) : (
        <main
          data-hook={this.pageName}
          {...styles(
            'root',
            {
              mobile: this.isMobile(),
              headerTextAlignment: settings.headerTextAlignment,
              imageRatio: settings.imageRatio,
              cropSelection: settings.cropSelection,
              contentTextAlignment: settings.contentTextAlignment,
              buttonState: settings.buttonState,
            },
            rest,
          )}
          style={{
            ...(viewMode === VIEW_MODE.Editor
              ? { width: '100vh', transform: 'translate(-10px, -10px)' }
              : {}),
          }}
        >
          {this.renderWithChallenge()}
        </main>
      )
    ) : null;
  }

  renderWithChallenge() {
    return (
      <>
        {this.renderNotifications()}
        {this.renderMedia(true)}
        {this.renderHeader()}
        {this.renderMedia(false)}
        {this.renderContent()}
        {this.renderStartDateSelection()}
        <Pricing
          className={styles.pricingSection}
          selectedPaymentOption={this.state.selectedPaymentOption}
          onPaymentSelected={(selectedPaymentOption) => {
            this.setState({ selectedPaymentOption });
          }}
          disabled={
            !!this.state.challengeJoinRestrictions.length ||
            isPricingPickingForbidden(
              this.props.challengeData.challenge,
              this.props.userType,
            )
          }
        />
        {this.renderFooter()}
      </>
    );
  }

  renderNotifications() {
    const { t, userType } = this.props;
    const { challengeJoinRestrictions } = this.state;
    const firstRestriction =
      challengeJoinRestrictions && challengeJoinRestrictions[0];
    let restrictionContent = '';

    switch (firstRestriction) {
      case IChallengeJoinRestriction.MAX_PARTICIPANTS:
        restrictionContent = t('challenge.page.restrictions.max-participants');

        break;
      case IChallengeJoinRestriction.SPECIFIC_STARTED:
        restrictionContent = t('challenge.page.restrictions.specific-started');

        break;
      default:
    }

    switch (userType) {
      case ParticipantState.JOIN_REQUESTED:
        restrictionContent = t('challenge.join-request.notification');
        break;
      default:
    }

    return firstRestriction || restrictionContent ? (
      <Notification
        className={styles.topNotification}
        data-hook={`${this.pageName}-top-notifications`}
        alignment={this.props.settings.headerTextAlignment}
        withErrorIcon={true}
        content={restrictionContent}
      />
    ) : null;
  }

  renderMedia(isForMobile) {
    const {
      challengeData: { challenge },
      settings,
    } = this.props;
    const isShouldBeRendered =
      settings.displayHeaderImage &&
      challenge.settings.description.media &&
      ((this.isMobile() && isForMobile) || (!this.isMobile() && !isForMobile));

    return isShouldBeRendered ? (
      <figure
        className={styles.imageWrapper}
        data-hook={
          isForMobile
            ? `${this.pageName}-mobile-image-wrapper`
            : `${this.pageName}-image-wrapper`
        }
      >
        <div className={styles.imageRatioBox}>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${imageDataToMediaUrl({
                ...(challenge.settings.description.media.image as IImageData),
                width: isForMobile ? 500 : 1000,
                height: isForMobile ? 400 : 800,
              })})`,
            }}
          />
        </div>
      </figure>
    ) : null;
  }

  renderHeader() {
    const {
      t,
      lng,
      challengeData: { challenge, isAvailableForJoinImmediately },
      inviteLink,
      userType,
      join,
      settings,
      enabled,
      cancelJoinRequest,
    } = this.props;

    const isBadgesEnabled = enabled(Challenges.showBadges);

    const {
      showHeaderDate,
      showOverview,
      showDuration,
      showParticipants,
      showSteps,
      overviewItemsCount,
      stepsCount,
    } = this.getBaseViewOptions(challenge, settings);
    const timelineLangPrefix = getCountPrefix(
      challenge.settings.timelineType.flexible &&
        challenge.settings.timelineType.flexible.duration.value,
      lng,
    );
    const durationString = serverTimelineTypeToClientTimelineString(
      challenge.settings.timelineType as any,
      lng,
      t,
      'challenge-card.duration-string.ongoing',
      `challenge-card.duration-string.flexible.days${timelineLangPrefix}`,
      `challenge-card.duration-string.flexible.weeks${timelineLangPrefix}`,
    );

    const durationLangPrefix = getCountPrefix(
      challenge.settings.timelineType.flexible &&
        challenge.settings.timelineType.flexible.duration.value,
      lng,
    );
    const stepsCountPrefix = getCountPrefix(stepsCount, lng);
    const participantsCountPrefix = getCountPrefix(
      challenge.participantsSummary.participantsNumber,
      lng,
    );

    return (
      <header className={styles.header}>
        <div className={styles.headerInner}>
          {showHeaderDate ? (
            <p
              className={styles.headerDateInfo}
              data-hook={`${this.pageName}-date`}
            >
              {durationString}
            </p>
          ) : null}

          <h1
            className={styles.headerTitle}
            data-hook={`${this.pageName}-title`}
          >
            {challenge.settings.description.title}
          </h1>

          {showOverview ? (
            <ul
              style={{
                gridTemplateColumns: `repeat(${overviewItemsCount}, 135px)`,
              }}
              className={styles.detailsList}
              data-hook={`${this.pageName}-details-list`}
            >
              {showDuration
                ? this.renderDetailsItem(
                    challenge.settings.timelineType.flexible.duration.value,
                    challenge.settings.timelineType.flexible.duration.unit ===
                      V1DurationUnit.DAYS
                      ? t(
                          `${this.translationName}.details.days${durationLangPrefix}`,
                        )
                      : t(
                          `${this.translationName}.details.weeks${durationLangPrefix}`,
                        ),
                    `${this.pageName}-details-item-duration`,
                  )
                : null}

              {showSteps
                ? this.renderDetailsItem(
                    stepsCount,
                    t(
                      `${this.translationName}.details.steps${stepsCountPrefix}`,
                    ),
                    `${this.pageName}-details-item-steps`,
                  )
                : null}

              {showParticipants
                ? this.renderDetailsItem(
                    challenge.participantsSummary.participantsNumber,
                    t(
                      `${this.translationName}.details.participants${participantsCountPrefix}`,
                    ),
                    `${this.pageName}-details-item-participants`,
                  )
                : null}
            </ul>
          ) : null}

          {isBadgesEnabled && (
            <RewardInfo
              className={styles.rewards}
              rewards={challenge.settings.rewards}
              icon={true}
            />
          )}

          {!this.state.challengeJoinRestrictions.length ? (
            <JoinButton
              id={JoinButtonIds.Top}
              paymentOption={this.state.selectedPaymentOption}
              t={t}
              disabled={this.state.isError}
              userType={userType}
              isAvailableForJoinImmediately={isAvailableForJoinImmediately}
              isMobile={this.isMobile()}
              buttonState={settings.buttonState}
              join={join}
              cancelJoinRequest={cancelJoinRequest}
              inviteLink={inviteLink}
              isMemberFlowExperimentEnabled={true}
              startDate={this.state.startDate}
              preJoinValidation={this.validation}
              memberWebAppButtonClick={this.props.memberWebAppButtonClick}
            />
          ) : null}
        </div>
      </header>
    );
  }

  renderDetailsItem(
    value: string | number,
    description: string,
    dataHook: string,
  ) {
    const { settings } = this.props;

    return (
      <li
        className={`${styles.detailsListItem} ${
          !settings.displayDivider && styles.detailsListItemNoDivider
        }`}
        data-hook={dataHook}
      >
        <span className={styles.detailsListItemValue}>{value}</span>
        <span className={styles.detailsListItemDescription}>{description}</span>
      </li>
    );
  }

  renderContent() {
    const {
      t,
      challengeData: { challenge },
      settings,
    } = this.props;

    return (
      <article
        className={styles.content}
        data-hook={`${this.pageName}-content`}
      >
        {challenge.settings.description.details ? (
          <PageSection title={t(`${this.translationName}.content-title`)}>
            <div className={styles.contentDescriptionDetails}>
              <ChallengeDetails
                isMobile={this.props.formFactor === 'Mobile'}
                details={challenge.settings.description.details}
              />
            </div>

            {settings.displayOneApp && (
              <p className={styles.contentDescription}>
                {t('challenge.description.requires-one-app')}{' '}
                <a
                  href={this.props.inviteLink}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  {t('challenge.description.mobile-app')}
                </a>
              </p>
            )}

            {settings.displayOwner ? (
              <UserBox
                imageUrl={challenge.owners[0].imageUrl}
                fullName={challenge.owners[0].fullName}
                dataHook={`${this.pageName}-author`}
                alignment={settings.contentTextAlignment}
              />
            ) : null}
          </PageSection>
        ) : null}
      </article>
    );
  }

  renderStartDateSelection() {
    const {
      t,
      challengeData: { challenge },
      userType,
      settings,
    } = this.props;

    if (
      isNeedToSelectStartDate(challenge, userType) &&
      !this.state.challengeJoinRestrictions.length
    ) {
      return (
        <PageSection
          className={styles.startDate}
          title={t(`${this.translationName}.start-date-selection.title`)}
          titleDataHook={`${this.pageName}-start-date-selection-title`}
          dataHook={`${this.pageName}-start-date-selection`}
        >
          <p
            ref={this.dateInputRef}
            className={styles.startDateSelectionDescription}
          >
            {t(`${this.translationName}.start-date-selection.description`)}
          </p>

          <DateInput
            className={styles.startDateSelectionInput}
            defaultValue={this.state.startDate}
            textAlignment={settings.contentTextAlignment}
            errorMessage={t(
              `${this.translationName}.start-date-selection.future-date-error`,
            )}
            onChange={(isError, value) => {
              this.setState({
                isError,
                startDate: value,
              });
            }}
            firstTouch={this.state.isStartDateFirstTouch}
          />
        </PageSection>
      );
    }

    return null;
  }

  renderFooter() {
    const {
      t,
      challengeData: { isAvailableForJoinImmediately },
      inviteLink,
      userType,
      promptLogin,
      join,
      settings,
      cancelJoinRequest,
    } = this.props;
    const { startDate } = this.state;

    if (userTypeHandlers.isJoinedAlready(userType)) {
      return null;
    }

    return !this.state.challengeJoinRestrictions.length ? (
      <div className={styles.footer} ref={this.footerRef}>
        <JoinButton
          id={JoinButtonIds.Bottom}
          t={t}
          paymentOption={this.state.selectedPaymentOption}
          dataHook={`${this.pageName}-cta-button`}
          disabled={this.state.isError}
          userType={userType}
          isAvailableForJoinImmediately={isAvailableForJoinImmediately}
          isMobile={this.isMobile()}
          buttonState={settings.buttonState}
          join={join}
          cancelJoinRequest={cancelJoinRequest}
          inviteLink={inviteLink}
          startDate={startDate}
          preJoinValidation={this.validation}
          isMemberFlowExperimentEnabled={true}
          memberWebAppButtonClick={this.props.memberWebAppButtonClick}
        />

        {userType === UserState.VISITOR ? (
          <p>
            {t(`${this.translationName}.already-a-member`)}{' '}
            <a
              href="#"
              className={styles.link}
              onClick={async (e) => {
                e && e.preventDefault();

                await promptLogin();
              }}
            >
              {t('challenge.login')}
            </a>
          </p>
        ) : null}
      </div>
    ) : null;
  }

  isMobile() {
    return this.props.formFactor === 'Mobile';
  }

  getBaseViewOptions(
    challenge: V1Challenge,
    settings: IChallengePageSettingsValues,
  ) {
    const showHeaderDate =
      settings.displayHeaderDate && !challenge.settings.timelineType.flexible;

    const stepsCount = challenge.stepsSummary.stepsNumber;
    const showSteps = settings.displayChallengeSteps && !!stepsCount;
    const showDuration =
      settings.displayChallengeDuration &&
      !!challenge.settings.timelineType.flexible;
    const showParticipants =
      settings.displayChallengeParticipants &&
      !!challenge.participantsSummary.participantsNumber;

    const showOverview = showParticipants || showDuration || showSteps;
    const overviewItemsCount =
      Number(showSteps) + Number(showDuration) + Number(showParticipants);

    return {
      showHeaderDate,
      stepsCount,
      showSteps,
      showDuration,
      showParticipants,
      showOverview,
      overviewItemsCount,
    };
  }

  scrollToFooter() {
    if (window && window.scrollTo && this.footerRef) {
      window.scrollTo({
        top: this.footerRef.current.offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
