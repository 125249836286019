import React from 'react';
import { IAccordionProps, Accordion } from '../Accordion';

import styles from './StepsAccordion.st.css';

export interface IStepsAccordionProps extends IAccordionProps {}

export const StepsAccordion: React.FC<IStepsAccordionProps> = props => {
  const { title, ...rest } = props;
  return (
    <Accordion title={title} {...styles('root', {}, rest)} {...rest}>
      {props.children}
    </Accordion>
  );
};
