import React from 'react';
import {
  format,
  addWeeks,
  subWeeks,
  compareAsc,
  addDays,
  differenceInDays,
} from 'date-fns';
import * as locales from 'date-fns/locale';
import {
  getRightDateFromBackend,
  getWeekRange,
  sureDateAfterSpecificDate,
} from '../../selectors/dates';

import { RangeSelector } from '../RangeSelector';

export interface IWeeksSelectorProps {
  locale: string;
  min: string;
  max: string;
  onChange(e): void;
  t(key: string, opts?: any): string;
  className?: string;
}

export interface IWeeksSelectorState {
  currentDate: Date;
}

export interface IGetWeekRange {
  from: Date;
  to: Date;
}

export class WeeksSelector extends React.PureComponent<
  IWeeksSelectorProps,
  IWeeksSelectorState
> {
  state = {
    currentDate: sureDateAfterSpecificDate(new Date(), this.getMinDate()),
  };

  getMinDate() {
    return getRightDateFromBackend(this.props.min);
  }

  getMaxDate() {
    return getRightDateFromBackend(this.props.max);
  }

  handleChange = (direction: 1 | -1) => {
    this.setState(
      direction > 0
        ? { currentDate: addWeeks(this.state.currentDate, 1) }
        : { currentDate: subWeeks(this.state.currentDate, 1) },
      () => {
        const range = getWeekRange(
          new Date(this.props.min),
          this.state.currentDate,
        );

        this.props.onChange(range);
      },
    );
  };

  render() {
    const { currentDate } = this.state;

    const { from, to } = getWeekRange(this.getMinDate(), currentDate);

    const isMinDisabled = compareAsc(from, this.getMinDate()) <= 0;
    const isMaxDisabled =
      this.props.max && compareAsc(addDays(to, 1), this.getMaxDate()) > 0;

    const start = format(from, 'dd');
    const end = format(to, 'dd');

    const monthFromString = format(from, 'MMM', {
      locale: locales[this.props.locale],
    });
    const monthToString = format(to, 'MMM', {
      locale: locales[this.props.locale],
    });

    const rangeString =
      monthFromString === monthToString
        ? `${monthFromString} ${start} - ${end}`
        : `${monthFromString} ${start} - ${monthToString} ${end}`;

    const isWeekOrLess =
      this.props.max &&
      differenceInDays(this.getMaxDate(), this.getMinDate()) <= 7;

    return (
      <RangeSelector
        value={`${this.props.t('weeks.prefix')} ${rangeString}`}
        nextText={isWeekOrLess ? null : this.props.t('weeks.button.next')}
        prevText={isWeekOrLess ? null : this.props.t('weeks.button.previous')}
        isPrevButtonDisabled={isMinDisabled || isWeekOrLess}
        isNextButtonDisabled={isMaxDisabled || isWeekOrLess}
        onNextClick={() => {
          !isMaxDisabled && this.handleChange(1);
        }}
        onPrevClick={() => {
          !isMinDisabled && this.handleChange(-1);
        }}
        className={this.props.className}
      />
    );
  }
}
