import * as React from 'react';

import {
  Button,
  PRIORITY as ButtonPRIORITY,
  SIZE as ButtonSIZE,
} from 'wix-ui-tpa/Button';
import { TextArea } from 'wix-ui-tpa/TextArea';

import { RadioButton } from '../../../../../components-shared/RadioButton';

import styles from './StepFeedback.st.css';

export interface IStepFeedbackSend {
  complexity?: number;
  comment?: string;
}

export interface IStepFeedbackProps {
  t(key: string): string;
  feedbackSettings: any;
  onSend(data: IStepFeedbackSend): void;
  onCancel(): void;
  className?: string;
  isMobile: boolean;
}

export interface IStepFeedbackState {
  complexity: number;
  comment: string;
}

export class StepFeedback extends React.Component<
  IStepFeedbackProps,
  IStepFeedbackState
> {
  static displayName = 'StepFeedback';

  constructor(props: IStepFeedbackProps) {
    super(props);

    this.state = {
      complexity: 2,
      comment: '',
    };
  }

  onSend = (complexityEnabled) => {
    if (this.props.onSend) {
      this.props.onSend({
        complexity: complexityEnabled ? this.state.complexity : null,
        comment: this.state.comment,
      });
    }
  };

  isSubmitEnabled = (
    complexityEnabled: boolean,
    commentEnabled: boolean,
  ): boolean => {
    if (commentEnabled && complexityEnabled) {
      return !!(this.state.comment && this.state.complexity);
    }

    if (commentEnabled) {
      return !!this.state.comment;
    }

    if (complexityEnabled) {
      return !!this.state.complexity;
    }

    return false;
  };

  render() {
    const { t, feedbackSettings = [], isMobile, ...rest } = this.props;
    let isComplexityEnabled = false;
    let complexityTitle = t('step-feedback.complexity.title');
    let isCommentEnabled = false;
    let commentTitle = t('step-feedback.comment.title');

    feedbackSettings.forEach((item) => {
      if (item?.type?.numeric) {
        isComplexityEnabled = true;

        if (item.question) {
          complexityTitle = item.question;
        }
      } else if (item?.type?.text) {
        isCommentEnabled = true;

        if (item.question) {
          commentTitle = item.question;
        }
      }
    });

    return (
      <div {...styles('root', { mobile: isMobile }, rest)}>
        <div className={styles.content}>
          <h1 className={styles.title}>{t('step-feedback.title')}</h1>
          <p className={styles.description}>{t('step-feedback.description')}</p>

          {isComplexityEnabled ? (
            <div className={styles.section}>
              <h2 className={styles.sectionTitle}>{complexityTitle}</h2>
              {this.renderComplexityItem(
                t('step-feedback.complexity.item.easy'),
                1,
              )}
              {this.renderComplexityItem(
                t('step-feedback.complexity.item.right'),
                2,
              )}
              {this.renderComplexityItem(
                t('step-feedback.complexity.item.hard'),
                3,
              )}
            </div>
          ) : null}

          {isCommentEnabled ? (
            <div className={styles.section}>
              <h2 className={styles.sectionTitle}>{commentTitle}</h2>
              <TextArea
                ariaLabel={t('step-feedback.comment.placeholder')}
                className={styles.comment}
                placeholder={t('step-feedback.comment.placeholder')}
                value={this.state.comment}
                onChange={(e) => {
                  this.setState({
                    comment: e.target.value,
                  });
                }}
              />
            </div>
          ) : (
            ''
          )}
        </div>

        <div className={styles.footer}>
          <Button
            className={styles.cancelButton}
            priority={ButtonPRIORITY.basicSecondary}
            size={ButtonSIZE.medium}
            onClick={this.props.onCancel}
          >
            {t('step-feedback.cancel')}
          </Button>

          <Button
            disabled={
              !this.isSubmitEnabled(isComplexityEnabled, isCommentEnabled)
            }
            className={styles.cta}
            priority={ButtonPRIORITY.basic}
            size={ButtonSIZE.medium}
            onClick={() => this.onSend(isComplexityEnabled)}
          >
            {t('step-feedback.cta')}
          </Button>
        </div>
      </div>
    );
  }

  renderComplexityItem(label: string, value: number) {
    return (
      <RadioButton
        className={styles.complexityItem}
        name="feedback-complexity"
        label={<span className={styles.complexityItemText}>{label}</span>}
        value={String(value)}
        checked={this.state.complexity === value}
        onChange={(option) => {
          this.setState({
            complexity: parseInt(option.value, 10),
          });
        }}
      />
    );
  }
}
