import React from 'react';
import { ResolveStepConsumer } from './ResolveStepContext';

export function withResolveStepData<IProps>(Component: any) {
  return props => {
    return (
      <ResolveStepConsumer>
        {value => {
          return <Component {...(props as IProps)} {...value} />;
        }}
      </ResolveStepConsumer>
    );
  };
}
