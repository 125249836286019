import React from 'react';

import { withGeneralData } from '../../contexts/GeneralDataProvider/withGeneralData';
import { IGeneralDataContext } from '../../contexts/GeneralDataProvider/GeneralDataContext';
import { Chevron } from './Chevron';

import styles from './RangeSelector.st.css';

export interface IRangeSelectorProps {
  value: string;
  nextText?: string;
  prevText?: string;
  isNextButtonDisabled: boolean;
  isPrevButtonDisabled: boolean;
  onNextClick(): void;
  onPrevClick(): void;
  className?: string;
}

export type IInnerRangeSelectorProps = IRangeSelectorProps &
  IGeneralDataContext;

const _RangeSelector: React.FC<IInnerRangeSelectorProps> = (props) => {
  const {
    formFactor,
    value,
    isNextButtonDisabled,
    isPrevButtonDisabled,
    onNextClick,
    onPrevClick,
    className,
  } = props;
  const mobile = formFactor === 'Mobile';

  return (
    <section {...styles('root', { mobile }, { className })}>
      <span className={styles.text}>{value}</span>
      <div>
        {props.prevText ? (
          <button
            onClick={onPrevClick}
            disabled={isPrevButtonDisabled}
            className={`${styles.button} ${styles.buttonPrev} ${
              isPrevButtonDisabled && styles.buttonDisabled
            }`}
          >
            <Chevron />
            {!!props.prevText && (
              <span className={styles.label}>{props.prevText}</span>
            )}
          </button>
        ) : null}

        {props.nextText ? (
          <button
            onClick={onNextClick}
            disabled={isNextButtonDisabled}
            className={`${styles.button} ${styles.buttonNext} ${
              isNextButtonDisabled && styles.buttonDisabled
            }`}
          >
            {!!props.nextText && (
              <span className={styles.label}>{props.nextText}</span>
            )}
            <Chevron />
          </button>
        ) : null}
      </div>
    </section>
  );
};

export const RangeSelector = withGeneralData<IRangeSelectorProps>(
  _RangeSelector,
);
