import React from 'react';
import { ParticipantStepsConsumer } from './ParticipantStepsContext';

export function withParticipantStepsData<IProps>(Component: any) {
  return props => {
    return (
      <ParticipantStepsConsumer>
        {value => {
          return <Component {...(props as IProps)} {...value} />;
        }}
      </ParticipantStepsConsumer>
    );
  };
}
