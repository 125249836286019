const BADGES_PROXY_URL =
  'https://www.wix.com/_api/challenges-web-proxy/badges-proxy';

let badges;

export const loadBadges = async (instance: string, ids: string[]) => {
  if (badges) {
    return badges;
  }

  if (!instance || !ids) {
    return;
  }

  try {
    badges = await (
      await fetch(BADGES_PROXY_URL, {
        method: 'POST',
        headers: {
          Authorization: instance,
        },
        body: JSON.stringify({
          query: {
            ids: {
              values: ids,
            },
          },
        }),
      })
    ).json();

    return badges;
  } catch (err) {
    console.error('Failed fetching Badges:', err);
  }
};
