import React from 'react';
import { translate } from 'react-i18next';

import { ReactComponent as TrophyIcon } from '../../assets/icons/trophy.svg';

import styles from './RewardInfo.st.css';

import {
  ChallengeReward,
  Trigger,
} from '@wix/ambassador-challenge-service-web/types';

export interface IRewardInfoProps {
  rewards: ChallengeReward[];
  className?: string;
  icon?: boolean;
  t(key: string): string;
}

const REWARD_TRANS_MAP = {
  [Trigger.ALL_STEPS_COMPLETED]: 'rewards.completed-all-steps',
  [Trigger.STEP_COMPLETED]: 'rewards.completed-first-step',
  [Trigger.JOINED_TO_CHALLENGE]: 'rewards.joined-challenge',
};

const _RewardInfo: React.FC<IRewardInfoProps> = (props) => {
  if (!props.rewards.length) {
    return null;
  }

  const rewardText = REWARD_TRANS_MAP[props.rewards[0].trigger];

  return (
    <div {...styles('root', {}, { className: props.className })}>
      {!!props.icon && (
        <span className={styles.icon}>
          <TrophyIcon />
        </span>
      )}
      <span className={styles.text}>{props.t(rewardText)}</span>
    </div>
  );
};

export const RewardInfo: React.FC<Omit<IRewardInfoProps, 't'>> = translate()(
  _RewardInfo,
);
