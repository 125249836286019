import * as React from 'react';
import {
  IChallengePageProps,
  IChallengePageState,
  IChallengePageSettingsValues,
} from './interfaces';

import { IWixSDKContext } from 'yoshi-flow-editor-runtime';

import {
  getChallengePaidPlans,
  IImageData,
  imageDataToMediaUrl,
  serverTimelineTypeToClientTimelineString,
  serverToClientPricing,
} from '@wix/challenges-web-library/dist/src';
import {
  V1DurationUnit,
  ChallengePricing,
  V1Challenge,
} from '@wix/ambassador-challenge-service-web/types';
import { Challenges } from '../../../editor/types/Experiments';
import { getCountPrefix } from '../../../selectors/langCount';
import get from 'lodash/get';

import { Spinner } from '../../../components-shared/Spinner';
import { JoinButton } from './components/JoinButton';
import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';

import styles from './WidgetComponentOld.st.css';
import { ChallengeDetails } from '../../../components-shared/ChallengeDetails';

export default class ChallengePage extends React.Component<
  IChallengePageProps & IWixSDKContext,
  IChallengePageState
> {
  static displayName = 'ChallengePage';
  public readonly pageName = 'challenge-page';

  render() {
    const { challengeData, isLoading, settings, ...rest } = this.props;

    return challengeData && challengeData.challenge ? (
      isLoading ? (
        <Spinner />
      ) : (
        <main
          data-hook={this.pageName}
          {...styles(
            'root',
            {
              headerTextAlignment: settings.headerTextAlignment,
              contentTextAlignment: settings.contentTextAlignment,
              imageRatio: settings.imageRatio,
              cropSelection: settings.cropSelection,
              mobile: this.isMobile(),
              buttonState: settings.buttonState,
            },
            rest,
          )}
        >
          {this.renderWithChallenge()}
        </main>
      )
    ) : null;
  }

  renderWithChallenge() {
    return (
      <>
        {this.renderMedia(true)}
        {this.renderHeader()}
        {this.renderMedia(false)}
        {this.renderContent()}
      </>
    );
  }

  renderMedia(isForMobile) {
    const {
      challengeData: { challenge },
      settings,
    } = this.props;

    return challenge.settings.description.media &&
      settings.displayHeaderImage &&
      ((this.isMobile() && isForMobile) ||
        (!this.isMobile() && !isForMobile)) ? (
      <figure
        className={styles.imageWrapper}
        data-hook={isForMobile ? 'mobile-image-wrapper' : 'image-wrapper'}
      >
        <div className={styles.ratioBox}>
          <div
            style={{
              backgroundImage: `url(${imageDataToMediaUrl({
                ...(challenge.settings.description.media.image as IImageData),
                width: isForMobile ? 500 : 1000,
                height: isForMobile ? 400 : 800,
              })})`,
            }}
            className={styles.media}
          />
        </div>
      </figure>
    ) : null;
  }

  renderHeader() {
    const {
      t,
      lng,
      challengeData: { challenge, isAvailableForJoinImmediately },
      inviteLink,
      userType,
      join,
      settings,
      userPaidPlans,
      enabled,
    } = this.props;

    const challengePaidPlans = getChallengePaidPlans(
      challenge.id,
      userPaidPlans,
    );
    const langPrefix = getCountPrefix(
      get(challenge.settings.timelineType, 'flexible.duration.value', 0),
      lng,
    );
    const durationString = serverTimelineTypeToClientTimelineString(
      challenge.settings.timelineType as any,
      lng,
      t,
      'challenge-card.duration-string.ongoing',
      `challenge-card.duration-string.flexible.days${langPrefix}`,
      `challenge-card.duration-string.flexible.weeks${langPrefix}`,
    );
    const pricingString = serverToClientPricing(
      (challenge.settings.pricing as any) as ChallengePricing,
      challengePaidPlans,
      t,
      'challenge-card.pricing.free',
      'challenge-card.pricing.paid.separator',
    );
    const viewOptions = this.getViewOptions(challenge, settings);
    const isMemberFlowExperimentEnabled = enabled(Challenges.paymentPage);

    return (
      <header className={styles.header + ' ' + styles.dateInfo}>
        <div className={styles.headerWrapper}>
          {!challenge.settings.timelineType.flexible &&
            settings.displayHeaderDate && (
              <span className={styles.headerInfo}>{durationString}</span>
            )}

          <h1 className={styles.headerTitle} data-hook="challenge-name">
            {challenge.settings.description.title}
          </h1>

          {settings.displayHeaderPrice && (
            <span
              data-hook="challenge-pricing"
              className={styles.headerInfo + ' ' + styles.priceInfo}
            >
              {pricingString}
            </span>
          )}

          {this.isMobile() && (
            <div
              className={styles.overviewMobile}
              data-hook="mobile-overview-wrapper"
            >
              {viewOptions.showDuration && (
                <React.Fragment>
                  <span
                    data-hook="mobile-overview-duration"
                    className={styles.overviewItem}
                  >
                    {durationString}
                  </span>
                </React.Fragment>
              )}
              {viewOptions.showParticipants && (
                <span
                  data-hook="mobile-overview-participants"
                  className={styles.overviewItem}
                >
                  {' '}
                  &bull; {challenge.participantsSummary.participantsNumber}{' '}
                  {t(
                    `challenge-card.participants${getCountPrefix(
                      challenge.participantsSummary.participantsNumber,
                      lng,
                    )}`,
                  )}
                </span>
              )}
              {viewOptions.showSteps && (
                <span
                  data-hook="mobile-overview-steps"
                  className={styles.overviewItem}
                >
                  {' '}
                  &bull; {viewOptions.stepsCount}{' '}
                  {t(
                    `challenge.settings.display.steps${getCountPrefix(
                      viewOptions.stepsCount,
                      lng,
                    )}`,
                  )}
                </span>
              )}
            </div>
          )}

          <JoinButton
            t={t}
            paymentOption={null}
            disabled={false}
            userType={userType}
            isAvailableForJoinImmediately={isAvailableForJoinImmediately}
            isMobile={this.isMobile()}
            buttonState={settings.buttonState}
            join={join}
            inviteLink={inviteLink}
            isMemberFlowExperimentEnabled={isMemberFlowExperimentEnabled}
          />
        </div>
      </header>
    );
  }

  renderContent() {
    const {
      t,
      lng,
      challengeData: { challenge, isAvailableForJoinImmediately },
      inviteLink,
      userType,
      join,
      settings,
      enabled,
    } = this.props;

    const viewOptions = this.getViewOptions(challenge, settings);
    const isMemberFlowExperimentEnabled = enabled(Challenges.paymentPage);

    return (
      <article className={styles.content}>
        {viewOptions.showOverviewDesktop && (
          <h2 data-hook="overview-title" className={styles.subtitle}>
            {t('challenge.overview')}
          </h2>
        )}

        {viewOptions.showOverviewDesktop && (
          <section
            style={{
              gridTemplateColumns: `repeat(${viewOptions.overviewItemsCount}, 135px)`,
            }}
            className={styles.info}
            data-hook="overview-wrapper"
          >
            {viewOptions.showDuration && (
              <div className={styles.infoItem}>
                <span className={styles.infoValue}>
                  {challenge.settings.timelineType.flexible.duration.value}
                </span>
                <span className={styles.infoDescription}>
                  {challenge.settings.timelineType.flexible.duration.unit ===
                  V1DurationUnit.DAYS
                    ? t(
                        `challenge-card.duration-string.flexible.days${getCountPrefix(
                          challenge.settings.timelineType.flexible.duration
                            .value,
                          lng,
                        )}`,
                      )
                    : t(
                        `challenge-card.duration-string.flexible.weeks${getCountPrefix(
                          challenge.settings.timelineType.flexible.duration
                            .value,
                          lng,
                        )}`,
                      )}
                </span>
              </div>
            )}
            {viewOptions.showSteps && (
              <div className={styles.infoItem}>
                <span className={styles.infoValue}>
                  {viewOptions.stepsCount}
                </span>
                <span className={styles.infoDescription}>
                  {t(
                    `challenge.settings.display.steps${getCountPrefix(
                      viewOptions.stepsCount,
                      lng,
                    )}`,
                  )}
                </span>
              </div>
            )}

            {viewOptions.showParticipants && (
              <div className={styles.infoItem}>
                <span className={styles.infoValue}>
                  {challenge.participantsSummary.participantsNumber}
                </span>
                <span className={styles.infoDescription}>
                  {t(
                    `challenge-card.participants${getCountPrefix(
                      challenge.participantsSummary.participantsNumber,
                      lng,
                    )}`,
                  )}
                </span>
              </div>
            )}
          </section>
        )}

        {challenge.settings.description.details && (
          <React.Fragment>
            <h2 className={styles.subtitle}>
              {t('challenge.about-challenge')}
            </h2>
            <div className={styles.description}>
              <ChallengeDetails
                isMobile={this.props.formFactor === 'Mobile'}
                details={challenge.settings.description.details}
              />
            </div>
          </React.Fragment>
        )}

        {settings.displayOwner && (
          <footer className={styles.author}>
            <Avatar
              src={challenge.owners[0].imageUrl}
              size={AvatarSize.large}
              name={challenge.owners[0].fullName}
              className={styles.authorPic}
            />
            <div className={styles.authorInfo}>
              <span className={styles.authorName}>
                {challenge.owners[0].fullName}
              </span>
            </div>
          </footer>
        )}

        <div className={styles.footerAction}>
          <JoinButton
            t={t}
            paymentOption={null}
            disabled={false}
            userType={userType}
            isAvailableForJoinImmediately={isAvailableForJoinImmediately}
            isMobile={this.isMobile()}
            buttonState={settings.buttonState}
            join={join}
            inviteLink={inviteLink}
            isMemberFlowExperimentEnabled={isMemberFlowExperimentEnabled}
          />
        </div>
      </article>
    );
  }

  isMobile() {
    return this.props.formFactor === 'Mobile';
  }

  getViewOptions(
    challenge: V1Challenge,
    settings: IChallengePageSettingsValues,
  ) {
    const stepsCount = challenge.stepsSummary.stepsNumber;
    const showSteps = !!stepsCount && settings.displayChallengeSteps;
    const showDuration =
      !!challenge.settings.timelineType.flexible &&
      settings.displayChallengeDuration;
    const showParticipants =
      !!challenge.participantsSummary.participantsNumber &&
      settings.displayChallengeParticipants;

    const showOverviewDesktop =
      !this.isMobile() && (showParticipants || showDuration || showSteps);
    const overviewItemsCount =
      Number(showSteps) + Number(showDuration) + Number(showParticipants);

    return {
      stepsCount,
      showSteps,
      showDuration,
      showParticipants,
      showOverviewDesktop,
      overviewItemsCount,
    };
  }
}
