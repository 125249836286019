import React from 'react';
import ReactDOM from 'react-dom';

import { CloseIcon } from './Close';
import { withGeneralData } from '../../contexts/GeneralDataProvider/withGeneralData';
import { IGeneralDataContext } from '../../contexts/GeneralDataProvider/GeneralDataContext';

import styles from './Modal.st.css';

export interface IModalProps {
  opened?: boolean;
  onClose(): void;
  className?: string;
  contentClassName?: string;
}

export type IInnerModalProps = IModalProps & IGeneralDataContext;

class _Modal extends React.PureComponent<IInnerModalProps> {
  el = null;
  modalRoot = null;

  componentDidMount() {
    this.el = document && document.createElement('div');
    this.modalRoot = document && document.getElementById('modal-root');
    this.modalRoot && this.modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalRoot && this.modalRoot.removeChild(this.el);
  }

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { formFactor, className, contentClassName } = this.props;
    const mobile = formFactor === 'Mobile';

    const modalContent = (
      <>
        <div className={styles.overlay} onClick={this.handleClose} />
        <section {...styles('root', { mobile }, { className })}>
          <div className={styles.header}>
            <button className={styles.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </button>
          </div>
          <div className={styles.content + ' ' + contentClassName}>
            {this.props.children}
          </div>
        </section>
      </>
    );
    return this.props.opened && ReactDOM.createPortal(modalContent, this.el);
  }
}

export const Modal = withGeneralData<IModalProps>(_Modal);
