import React from 'react';
import styles from './PageSection.st.css';
import { withGeneralData } from '../../contexts/GeneralDataProvider/withGeneralData';
import { IGeneralDataContext } from '../../contexts/GeneralDataProvider/GeneralDataContext';

interface IPageSectionProps {
  title: string;
  dataHook?: string;
  titleDataHook?: string;
  className?: string;
}

export type IPageSection = IGeneralDataContext & IPageSectionProps;

const _PageSection: React.FC<IPageSection> = props => {
  const { formFactor } = props;
  const mobile = formFactor === 'Mobile';

  return (
    <section
      {...styles(`${styles.root} ${props.className}`, { mobile })}
      data-hook={props.dataHook}
    >
      <h2 className={styles.title} data-hook={props.titleDataHook}>
        {props.title}
      </h2>
      <div className={styles.content}>{props.children}</div>
    </section>
  );
};

export const PageSection = withGeneralData<IPageSectionProps>(_PageSection);
