import React from 'react';

export const UnselectedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        opacity="0.6"
      >
        <circle strokeWidth="1" stroke="#000000" cx="8" cy="8" r="7.5"></circle>
      </g>
    </svg>
  );
};
