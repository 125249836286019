import { getPricingViewOptions } from '../../../../../../services/Pricing/getPricingViewOptions';
import {
  getChallengePaidPlans,
  IUserPaidPlan,
  serverPricingToClientPriceAndCurrencyString,
} from '@wix/challenges-web-library/dist/src';
import { PAID_PLAN_DATA_HOOK, SINGLE_PAYMENT_DATA_HOOK } from '../constants';
import { SelectedPaymentOption } from '../interfaces';
import { getPaidPlanStartPrice } from './getPaidPlanPrice';
import { V1Challenge } from '@wix/ambassador-challenge-service-web/types';
import { RadioOption } from '../../RadioGroup/RadioGroup';
import { getPaidPlanName } from './getPaidPlanName';

export interface IPricingOption extends RadioOption {
  value: SelectedPaymentOption;
}

export function getPricingOptions(
  challenge: V1Challenge,
  userPaidPlans: IUserPaidPlan[],
  t: (key: string) => string,
): IPricingOption[] {
  const {
    challengePricing,
    isHasPaidPlans,
    isHasSinglePayment,
  } = getPricingViewOptions(challenge, userPaidPlans);

  const challengePaidPlans = getChallengePaidPlans(challenge.id, userPaidPlans);

  const pricingOptions = [];

  if (isHasSinglePayment) {
    const SINGLE_PAYMENT = {
      dataHook: SINGLE_PAYMENT_DATA_HOOK,
      title: t(`challenge.page.pricing-options.single-payment`),
      valueTitle: serverPricingToClientPriceAndCurrencyString(
        challengePricing as any,
      ),
      value: SelectedPaymentOption.SinglePayment,
    };

    pricingOptions.push(SINGLE_PAYMENT);
  }

  if (isHasPaidPlans) {
    const PAID_PLANS = {
      dataHook: PAID_PLAN_DATA_HOOK,
      title: getPaidPlanName(challengePaidPlans, t),
      valueTitle: getPaidPlanStartPrice(challengePaidPlans, t),
      value: SelectedPaymentOption.PaidPlans,
    };
    pricingOptions.push(PAID_PLANS);
  }

  return pricingOptions;
}
