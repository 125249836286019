import * as React from 'react';
import { Popover } from 'wix-ui-core/dist/src/components/popover';
import { ActionsMenuLayout } from 'wix-ui-tpa/ActionsMenuLayout';
import { Ellipses } from '../icons/Ellipses';
import styles from './Dots.st.css';

export interface IDotsProps {
  leaveTheChallengeText: string;
  leaveTheChannelCallback(): void;
  onOpenMenu?(): void;
}

interface IDotsState {
  isShown: boolean;
}

export class Dots extends React.Component<IDotsProps, IDotsState> {
  static displayName = 'Dots';

  constructor(props: IDotsProps) {
    super(props);

    this.state = {
      isShown: false,
    };
  }

  onClick = () => {
    this.setState(
      (state) => ({
        isShown: !state.isShown,
      }),
      () => {
        if (this.state.isShown && this.props.onOpenMenu) {
          this.props.onOpenMenu();
        }
      },
    );
  };

  onClickOutside = () => {
    this.setState({
      isShown: false,
    });
  };

  render() {
    return (
      <div {...styles('root')}>
        <Popover
          className={styles.popover}
          shown={this.state.isShown}
          dynamicWidth={true}
          maxWidth={250}
          onClick={this.onClick}
          onClickOutside={this.onClickOutside}
          placement="bottom-end"
          showArrow={false}
        >
          <Popover.Element>
            <Ellipses />
          </Popover.Element>
          <Popover.Content>
            <ActionsMenuLayout>
              <ActionsMenuLayout.Item
                content={this.props.leaveTheChallengeText}
                onClick={() => {
                  this.setState(
                    {
                      isShown: false,
                    },
                    () => {
                      this.props.leaveTheChannelCallback();
                    },
                  );
                }}
              />
            </ActionsMenuLayout>
          </Popover.Content>
        </Popover>
      </div>
    );
  }
}
