import * as React from 'react';
import { translate } from 'react-i18next';
import { IChallengePageProps } from './interfaces';

import { withGeneralData } from '../../../contexts/GeneralDataProvider/withGeneralData';
import { withResolveStepData } from '../../../contexts/ResolveStep/withResolveStepData';
import { withParticipantStepsData } from '../../../contexts/ParticipantStepsDataProvider/withParticipantStepsData';
import { withChallengeData } from '../../../contexts/ChallengeDataProvider/withChallengeData';
import { withToast } from '../../../contexts/ToastContext/withToast';
import { withUser } from '../../../contexts/User/withUser';
import { withLocation } from '../../../contexts/Location/withLocation';
import { withInviteLink } from '../../../contexts/InviteLink/InviteLinkContext';
import { withExperiments } from '../../../contexts/Experiments/ExperimentsContext';
import { withBI } from '../../../contexts/BI/withBI';
import { withSettings } from '../../../contexts/Settings/withSettings';
import { withProviders } from '../../../contexts/main/withProviders';
import { IWixSDKContext } from 'yoshi-flow-editor-runtime';

// import { Challenges } from '../../../editor/types/Experiments';

import WidgetComponent from './WidgetComponent';
import WidgetComponentForParticipant from './WidgetComponentForParticipant';
import WidgetComponentOld from './WidgetComponentOld';
import { withPaidPlans } from '../../../contexts/PaidPlans/paidPlansContext';
import { applyProviders } from '../../../services/applyProviders';
import { IProvidersProps } from '../../../contexts/main/intefaces';
import { isParticipantPage } from '../../../selectors/participants';

export class ChallengePage extends React.Component<
  IChallengePageProps & IWixSDKContext
> {
  static displayName = 'ChallengePageContainer';

  render() {
    const { participant, viewMode, userType } = this.props;
    // const isMemberFlowExperimentEnabled = enabled(Challenges.paymentPage);
    const isMemberFlowExperimentEnabled = this.props.isMemberFlowEnabled;

    return isMemberFlowExperimentEnabled ? (
      <>
        <div id="modal-root" />
        <div id="toast-root" />
        {isParticipantPage({
          participant,
          userType,
          viewMode,
        }) ? (
          <WidgetComponentForParticipant {...this.props} />
        ) : (
          <WidgetComponent {...this.props} />
        )}
      </>
    ) : (
      <WidgetComponentOld {...this.props} />
    );
  }
}

export default applyProviders<React.FC<Partial<IProvidersProps>>>(
  ChallengePage,
  [
    withProviders,
    withSettings,
    withBI,
    withExperiments,
    withInviteLink,
    withLocation,
    withUser,
    withToast,
    withGeneralData,
    withChallengeData,
    withParticipantStepsData,
    withResolveStepData,
    withPaidPlans,
    translate(),
  ],
);
