import React from 'react';
import { ToastContextConsumer } from './ToastContext';

export function withToast<T>(Component: any): React.FC<T> {
  return props => {
    return (
      <ToastContextConsumer>
        {value => {
          return <Component {...(props as T)} {...value} />;
        }}
      </ToastContextConsumer>
    );
  };
}
